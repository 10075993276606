import {
  Button,
  Grid,
  TextField,
  Typography,
  Container,
} from "@material-ui/core";
import React, { useState } from "react";
import WebStyles from "../../WebStyles.module.css";
import clsx from "clsx";
import HomeWebForm from "../home/HomeWebForm";
import { WEBSITE_DOMAIN_URL } from "../websiteConstants";

export default function MoreGreatFeatures({
  smUp,
  mdUp,
  lgUp,
  lgDown,
  xsDown,
  commonPadding,
}) {
  const [showWaitListForm, setShowWaitListForm] = useState(false);
  const handleClose = () => {
    setShowWaitListForm(false);
  };
  return (
    <Grid
      item
      container
      xs={12}
      style={{
        backgroundImage: lgUp
          ? "url('/images/service_providers/Blue_patch.svg')"
          : "none",
        backgroundRepeat: "no-repeat",
        backgroundSize: "10%",
        padding: commonPadding,
      }}
    >
      <HomeWebForm
        open={showWaitListForm}
        who={"serviceProvider"}
        handleClose={handleClose}
      ></HomeWebForm>
      <Container>
        <Grid
          item
          container
          xs={12}
          style={{
            // paddingBottom: lgUp ? 60 : 24,
            paddingBottom: 60,
            paddingTop: 16,
            flexDirection: smUp ? "row" : "column-reverse",
          }}
        >
          <Grid
            item
            container
            xs={12}
            md={7}
            lg={6}
            style={{ alignContent: "center", zIndex: 1, position: "relative" }}
          >
            <div
              variant="inherit"
              className={clsx(WebStyles.blueHeaderTxt, WebStyles.hankrndFont)}
              style={{
                fontSize: mdUp ? 60 : 30,
                lineHeight: mdUp ? "74px" : "32px",
                textAlign: "left",
                marginBottom: 20,
                fontWeight: 600,
              }}
            >
              {/* Increase your opportunities */}
              Strengthen homeowner relationships
            </div>
            <div
              variant="inherit"
              className={clsx(WebStyles.paraBlackText, WebStyles.barlowFont)}
              style={{
                fontSize: mdUp ? 28 : 14,
                lineHeight: mdUp ? "38px" : "19px",
                textAlign: "left",
                marginBottom: 24,
                paddingRight: 24,
              }}
            >
              {/* {`Generate leads. Save time. Cut costs. Elevate your client
              experiences.`} */}
              {`Build long-term client relationships that increase repeat & referral business, while reducing marketing expenses, and expanding revenue opportunities`}
            </div>
            <Grid item container xs={12} alignItems="center">
              {/* <Grid
                item
                container
                xs={12}
                md={7}
                lg={7}
                style={{
                  marginBottom: mdUp ? 0 : 24,
                }}
              >
                <Grid item container xs={11}>
                  <TextField
                    fullWidth
                    id="standard-basic"
                    label="Your email"
                    inputProps={{
                      className: WebStyles.nunitoFont,
                    }}
                  />
                </Grid>
              </Grid> */}
              <Grid
                item
                container
                xs={12}
                md={5}
                lg={7}
                style={{
                  display: "flex",
                  justifyContent: mdUp ? "flex-start" : "flex-end",
                }}
              >
                <Button
                  style={{
                    color: "#ffffff",
                    backgroundColor: "#72CAC9",
                    borderRadius: mdUp ? 24 : 12,
                    padding: "8px 20px",
                    fontSize: mdUp ? 20 : 14,
                    textTransform: "none",
                    height: 50,
                    marginRight: xsDown && 24,
                  }}
                  onClick={() => {
                    // setShowWaitListForm(true);
                    window.open(`https://${WEBSITE_DOMAIN_URL}/new`, "_blank");
                  }}
                >
                  <Grid className={WebStyles.hankrndFont}>
                    {/* Sign up now for free! */}
                    Join Souqh Today
                  </Grid>
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            md={5}
            lg={6}
            style={{ justifyContent: "center" }}
          >
            <Grid
              container
              item
              xs={12}
              style={{
                width: "100%",
                height: smUp ? 570 : 260,
                justifyContent: "center",
                zIndex: 0,
                position: "relative",
              }}
            >
              {/* <img
                style={{ height: "100%", width: "100%" }}
                src="/images/service_providers/people.svg"
                alt="opportunity"
                title="opportunity"
              ></img> */}

              <iframe
                style={{
                  width: lgUp ? 550 : "100%",
                  height: lgUp ? 382 : mdUp ? 459 : 201,
                  marginTop: mdUp && 56
                }}
                src="https://www.youtube.com/embed/2OjoPEBrkQI?autoplay=1&rel=0&mute=1&modestbranding=1&loop=0"
                title="Souqh: The Ultimate Realtor Collaboration Platform for Seamless Homeowner Journeys!"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              ></iframe>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
}
