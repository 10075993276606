import React from "react";
import { Typography, Grid, Container } from "@material-ui/core";
import WebStyles from "../../WebStyles.module.css";
import clsx from "clsx";

function OurTeam({ smUp, mdUp, lgUp, xlUp, commonPadding }) {
  return (
    <Grid
      item
      container
      xs={12}
      style={{
        backgroundImage: lgUp
          ? "url('/images/aboutus/orange_patch.svg')"
          : "none",
        backgroundRepeat: "no-repeat",
        backgroundSize: "7%",
        padding: commonPadding,
        backgroundPosition: "100%",
      }}
    >
      <Container>
        <Grid
          container
          item
          xs={12}
          style={{ marginBottom: xlUp ? 74 : mdUp ? 62 : 22 }}
        >
          <Grid
            className={clsx(WebStyles.samonHeaderTxt, WebStyles.hankrndFont)}
            style={{
              marginTop: 16,
              fontSize: xlUp ? 80 : mdUp ? 60 : 40,
              lineHeight: xlUp ? "74px" : mdUp ? "48px" : "24px",
              textAlign: mdUp ? "left" : "center",
              marginBottom: 24,
              fontWeight: 600,
              paddingLeft: mdUp && 48,
            }}
          >
            Our team
          </Grid>
          <Grid
            className={clsx(WebStyles.barlowFont)}
            style={{
              fontSize: mdUp ? 28 : 14,
              lineHeight: mdUp ? "38px" : "19px",
              textAlign: "left",
              paddingLeft: mdUp && 48,
            }}
          >
            We are team of tech-savvy realtors, mortgage brokers, lawyers and
            real estate professionals - on a mission to simplify the home buying
            and ownership journey by building an end-to-end, digital real estate
            services marketplace in Canada.
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
}

export default OurTeam;
