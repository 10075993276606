import {
  Divider,
  Grid,
  Typography,
  Link,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useProvinces } from "souqh-react-redux-hooks/useProvinces";
import WebStyles from "../WebStyles.module.css";
import clsx from "clsx";

export default function CitiesPage() {
  const { provinces } = useProvinces(1, true);
  const history = useHistory();
  const theme = useTheme();
  const lgUp = useMediaQuery(theme.breakpoints.up("lg"));
  const xlUp = useMediaQuery(theme.breakpoints.up("xl"));
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const commonPadding = lgUp ? "0px 4%" : "0px 2%";

  return (
    <div style={{ padding: commonPadding }}>
      <Grid
        className={clsx(WebStyles.samonHeaderTxt, WebStyles.hankrndFont)}
        style={{
          marginTop: 40,
          fontSize: xlUp ? 80 : mdUp ? 40 : 24,
          lineHeight: mdUp ? "74px" : "32px",
          fontWeight: 600,
        }}
      >
        CANADA
      </Grid>
      {provinces.map((item, index) => {
        return (
          <Grid
            item
            xs={12}
            // md={4}
            container
            key={index}
            style={{
              marginBottom: 16,
            }}
          >
            <Grid item xs={12} style={{ marginBottom: 20 }}>
              <Grid
                className={clsx(WebStyles.navyBlueColor, WebStyles.barlowFont)}
                align="left"
                style={{
                  marginTop: 16,
                  textAlign: "left",
                  fontSize: mdUp ? 28 : 14,
                  lineHeight: mdUp ? "38px" : "19px",
                }}
              >
                {item.name}
              </Grid>
            </Grid>
            {item.cityDTO.map((el) => {
              if (el.id !== 1739) {
                return (
                  <Grid item xs={3} style={{ marginBottom: 20 }}>
                    <Link
                      href={`${el.shortName}/categories`}
                      className={clsx(WebStyles.link, WebStyles.barlowFont)}
                      // to={`${el.name}/categories`}
                      // onClick={() => {
                      //   history.push({
                      //     pathname: `${el.shortName}/categories`,
                      //     // search: `?serviceRequestId=${data.serviceRequestId}`,
                      //     state: {
                      //       clickedCity: el.name,
                      //       clickedProvince: item.name,
                      //     },
                      //   });
                      // }}
                    >
                      {el.name}
                    </Link>
                  </Grid>
                );
              } else {
                return null;
              }
            })}
            <Divider
              orientation="horizontal"
              // variant="fullWidth"
              style={{ width: "100%", margin: "36px 0px 10px 0px" }}
            />
          </Grid>
        );
      })}
    </div>
  );
}
