import {
  AppBar,
  Button,
  Container,
  Grid,
  Hidden,
  Popover,
  Typography,
  useMediaQuery,
  useTheme,
  Link as MaterialLink,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Slide from "@material-ui/core/Slide";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import { Link, useLocation } from "react-router-dom";
import WebStyles from "../WebStyles.module.css";
import clsx from "clsx";
import SiteMenu from "./siteMenu/SiteMenu";
import MenuIcon from "@material-ui/icons/Menu";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { WEBSITE_DOMAIN_URL } from "./websiteConstants";
// import MegaMenuDropdown from "./MegaMenuDropdown";

export const APP_BAR_HEIGHT_DESKTOP = 215;
export const APP_BAR_HEIGHT_MOBILE = 162;

function HideOnScroll(props) {
  const { children } = props;

  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

function Header(props) {
  const theme = useTheme();
  let location = useLocation();

  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const lgUp = useMediaQuery(theme.breakpoints.up("lg"));
  const lgDown = useMediaQuery(theme.breakpoints.down("lg"));
  const [openMenu, setOpenMenu] = useState(false);
  const closeMenuBar = () => {
    setOpenMenu(false);
  };
  const xlUp = useMediaQuery(theme.breakpoints.up("xl"));

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMegaClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMegaClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const megaopen = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    let title = "Souqh | Home journey, simplified";
    if (location && location.pathname) {
      switch (location.pathname) {
        case "/contact-us":
          title =
            // "Souqh | We help you ditch the stress in your home buying journey";
            "Souqh | Contact Us";
          break;
        case "/about-us":
          // title = "Souqh | Canada's digital real esatate services marketplace";
          title = "Souqh | About Us";
          break;
        case "/home-buyers":
          title = "Souqh | Your one-stop connection to home buying resources";
          // title = "Souqh | Home Buyers/Owners";
          break;
        case "/service-providers":
          // title = "Souqh | Increase your opportunities";
          title = "Souqh | Service Providers";
          break;

        default:
          title = "Souqh | Canada's Real Estate + Home Services Marketplace";
          break;
      }
    }
    document.title = title;
  }, [location]);

  return (
    <HideOnScroll {...props}>
      <AppBar
        elevation={0}
        style={{
          height: xlUp
            ? APP_BAR_HEIGHT_DESKTOP
            : mdUp
            ? 192
            : APP_BAR_HEIGHT_MOBILE,
          backgroundColor: "#ffffff",
          color: "black",
        }}
      >
        {lgDown ? (
          <SiteMenu closeMenuBar={closeMenuBar} openMenu={openMenu} />
        ) : null}
        <Container>
          <Container>
            <Grid
              lg={12}
              xs={12}
              container
              item
              style={{ marginTop: 20, marginBottom: 15 }}
              justify="flex-start"
            >
              <Grid
                container
                item
                xs={12}
                md={5}
                lg={5}
                // style={{ paddingLeft: mdUp ? 60 : 10 }}
              >
                <Grid sm={1} xs={2} item container justify="flex-start">
                  <Link
                    to={{
                      pathname:
                        "https://www.facebook.com/Souqh-105815918284357",
                    }}
                    target="_blank"
                  >
                    <img
                      src="/images/footer/facebook.svg"
                      className={WebStyles.socialIcon}
                      alt="Facebook Social Icon"
                    />
                  </Link>
                </Grid>
                <Grid sm={1} xs={2} item container justify="flex-start">
                  <Link
                    to={{
                      pathname: "https://www.instagram.com/souqhteam/",
                    }}
                    target="_blank"
                  >
                    <img
                      src="/images/footer/Instagram.svg"
                      className={WebStyles.socialIcon}
                      alt="Instagram Social Icon"
                    />
                  </Link>
                </Grid>
                <Grid sm={1} xs={2} item container justify="flex-start">
                  <Link
                    to={{
                      pathname: "https://twitter.com/souqhteam",
                    }}
                    target="_blank"
                  >
                    <img
                      src="/images/footer/Twitter.svg"
                      className={WebStyles.socialIcon}
                      alt="Twitter Social Icon"
                    />
                  </Link>
                </Grid>
                <Grid sm={1} xs={2} item container justify="flex-start">
                  <Link
                    to={{
                      pathname: "https://www.linkedin.com/company/souqh/",
                    }}
                    target="_blank"
                  >
                    <img
                      src="/images/footer/LinkedIn.svg"
                      className={WebStyles.socialIcon}
                      alt="LinkedIn Social Icon"
                    />
                  </Link>
                </Grid>
                <Grid sm={1} xs={2} item container justifyContent="flex-start">
                  <Link
                    to={{
                      pathname:
                        "https://www.youtube.com/channel/UClH1brV9v85OAt32B5_uLTg",
                    }}
                    target="_blank"
                  >
                    <img
                      src="/images/footer/youtube.svg"
                      className={WebStyles.socialIcon}
                      alt="Youtube Social Icon"
                    />
                  </Link>
                </Grid>
              </Grid>
              {lgUp ? (
                <Grid container item xs={7} justify="flex-end">
                  <Grid container item xs={1}>
                    <Grid
                      style={{
                        fontSize: mdUp ? 16 : 8,
                        fontWeight: 700,
                        color: "#1B1E4C",
                      }}
                      className={clsx(WebStyles.nunitoFont)}
                    >
                      <a
                        style={{
                          textDecoration: "none",
                          color: "#1B1E4C",
                          textDecoration: "none",
                          fontSize: mdUp ? 16 : 8,
                          fontWeight: 700,
                          marginRight: 20,
                          color: "#1B1E4C",
                        }}
                        href="https://blog.souqh.ca/"
                        target="_blank"
                      >
                        Blog
                      </a>
                    </Grid>
                  </Grid>
                  <Link
                    style={{
                      textDecoration: "none",
                      fontSize: mdUp ? 16 : 8,
                      fontWeight: 700,
                      marginRight: 20,
                      color: "#1B1E4C",
                    }}
                    className={clsx(WebStyles.nunitoFont)}
                    to="/about-us"
                  >
                    About Us
                  </Link>

                  <Link
                    style={{
                      textDecoration: "none",
                      marginRight: 20,
                      fontSize: mdUp ? 16 : 8,
                      fontWeight: 700,
                      color: "#1B1E4C",
                    }}
                    className={clsx(WebStyles.nunitoFont)}
                    to="/contact-us"
                  >
                    Contact Us
                  </Link>

                  <Grid container item xs={2} onClick={handleClick}>
                    <Grid
                      style={{
                        fontSize: mdUp ? 16 : 8,
                        fontWeight: 700,
                        color: "#1B1E4C",
                      }}
                      className={clsx(WebStyles.nunitoFont)}
                    >
                      Login
                    </Grid>
                    <KeyboardArrowDownIcon style={{ color: "#1B1E4C" }} />
                  </Grid>

                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    style={{ paddingTop: 10 }}
                  >
                    <div style={{ padding: 15 }}>
                      <Grid
                        style={{
                          color: "#1B1E4C",
                          fontSize: 14,
                          fontWeight: 400,
                        }}
                        className={clsx(WebStyles.nunitoFont)}
                      >
                        <a
                          style={{ textDecoration: "none", color: "#1B1E4C" }}
                          href={`https://${WEBSITE_DOMAIN_URL}/userlogin`}
                          target="_blank"
                        >
                          Home Buyers/Owners
                        </a>
                      </Grid>
                      <Grid
                        style={{
                          color: "#1B1E4C",
                          fontSize: 14,
                          marginTop: 5,
                          fontWeight: 500,
                        }}
                        className={clsx(WebStyles.nunitoFont)}
                      >
                        <a
                          style={{ textDecoration: "none", color: "#1B1E4C" }}
                          href={`https://${WEBSITE_DOMAIN_URL}/login`}
                          target="_blank"
                        >
                          Service Providers
                        </a>
                      </Grid>
                    </div>
                  </Popover>
                </Grid>
              ) : null}
            </Grid>
            <Grid style={{ position: "relative" }}>
              <div
                style={{
                  position: "absolute",
                  left: smDown ? -48 : -72,
                  right: 0,
                  zIndex: -1,
                  height: smDown ? 98 : 143,
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    backgroundColor: "#1B1E4C",
                    // border-top-left-radius: 72,
                    // border-bottom-left-radius: 72,
                    borderRadius: smDown ? 48 : 72,
                    right: -2000,
                    left: 0,
                    bottom: 0,
                    top: 0,
                  }}
                ></div>
              </div>

              <Grid
                alignContent="center"
                container
                style={{
                  height: smDown ? 98 : 143,
                  backgroundColor: "#1B1E4C",
                  // borderRadius: smDown ? 0 : 72,
                  color: "white",
                }}
              >
                <Grid
                  container
                  justify="space-between"
                  alignContent="center"
                  style={{
                    color: "white",
                  }}
                >
                  <Link to="/">
                    <img
                      src="/images/Souqh w logo tagline.svg"
                      style={{ height: "100%", width: smDown ? 150 : "100%" }}
                      alt="Souqh Logo"
                      title="Souqh Logo"
                    />
                  </Link>
                  <Hidden lgUp>
                    <MenuIcon
                      style={{
                        marginTop: 12,
                        height: smDown ? 30 : 48,
                        width: smDown ? 30 : 48,
                      }}
                      onClick={() => {
                        setOpenMenu(true);
                      }}
                    />
                  </Hidden>
                  {lgUp ? (
                    <Grid>
                      <Grid
                        container
                        item
                        style={{ height: "100%", alignContent: "center" }}
                      >
                        <MaterialLink
                          style={{
                            textDecoration: "none",
                            marginRight: 20,
                            color: "white",
                            fontWeight: "bold",
                            fontSize: 20,
                          }}
                          href={`https://${WEBSITE_DOMAIN_URL}/marketplace`}
                          target="_blank"
                          className={clsx(WebStyles.nunitoFont)}
                        >
                          Marketplace
                        </MaterialLink>
                        <Link
                          style={{
                            textDecoration: "none",
                            marginRight: 20,
                            color: "white",
                            fontWeight: "bold",
                            fontSize: 20,
                          }}
                          to="/home-buyers"
                          className={clsx(WebStyles.nunitoFont)}
                        >
                          Home Buyers/Owners
                        </Link>
                        <Link
                          style={{
                            textDecoration: "none",
                            marginRight: 20,
                            color: "white",
                            fontWeight: "bold",
                            fontSize: 20,
                          }}
                          to="/service-providers"
                          className={clsx(WebStyles.nunitoFont)}
                        >
                          Service Providers
                        </Link>
                        {/* <MegaMenuDropdown></MegaMenuDropdown> */}
                      </Grid>
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Container>
      </AppBar>
    </HideOnScroll>
  );
}

export default Header;
