import { Grid, Container } from "@material-ui/core";
import React from "react";
import WebStyles from "../../WebStyles.module.css";
import clsx from "clsx";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

function HomeBuyingFuture({ smUp, mdUp, lgUp, smDown, commonPadding }) {
  const height = lgUp ? 420 : 360;
  const radius = height / 2;

  let features = ["Securing a pre-approval",
    "Arranging home financing",
    "Scheduling home inspections",
    "Performing virtual closings with lawyers",
    "Hiring movers, live chat support, address updates and more!",
    "Posting and managing any type of home improvement and renovations"]

  return (
    <Grid
      item
      container
      xs={12}
      style={{
        padding: commonPadding,
        position: "relative",
        zIndex: 1,
        marginTop: 56,
      }}
    >
      {smUp ? (
        <div
          style={{
            position: "absolute",
            backgroundColor: "#B872AF",
            height: height,
            right: -2000,
            left: lgUp ? "0%" : "-15%",
            bottom: 0,
            top: lgUp ? "-75%" : "-50%",
            zIndex: -1,
            borderTopLeftRadius: lgUp ? radius : "0px",
            borderBottomLeftRadius: lgUp ? radius : "0px",
            transform: "rotate(-10deg)",
            transformOrigin: "center",
            opacity: 0.1,
          }}
        ></div>
      ) : null}

      <Container>
        <Container>
          <Grid
            container
            justifyContent="center"
            direction={lgUp ? "row" : "column-reverse"}
          >
            <Grid container item xs={12} lg={6} alignContent="center">
              <Grid
                className={clsx(WebStyles.captionViolet, WebStyles.hankrndFont)}
                style={{
                  textAlign: smUp ? "left" : "center",
                  fontSize: mdUp ? 40 : 20,
                  lineHeight: mdUp ? "48px" : "24px",
                  marginTop: smDown ? 10 : 0,
                  fontWeight: 600,
                }}
              >
                {/* Come see how Souqh is<br></br> re-shaping the future of<br></br>{" "}
                home buying */}
                Simplifying Real Estate, Together
              </Grid>
              <Grid
                className={clsx(WebStyles.paraBlackText, WebStyles.nunitoFont)}
                style={{
                  textAlign: smUp ? "left" : "center",
                  fontSize: mdUp ? 28 : 14,
                  lineHeight: mdUp ? "32px" : "16px",
                  marginTop: 20,
                }}
              >
                {/* Simply connect, collaborate and transact – all in one place */}
                <Grid
                  className={clsx(WebStyles.paraBlackText, WebStyles.nunitoFont)}
                  style={{
                    textAlign: "left",
                    fontSize: mdUp ? 28 : 14,
                    lineHeight: mdUp ? "32px" : "16px",
                    marginTop: 6,
                  }}
                >
                  {features.map((item) => <Grid item>
                    <CheckCircleIcon
                      style={{ color: "#107E3E", marginTop: 3 }}
                    />{item}</Grid>)}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={6}>
              {/* <video
                // src="https://dl.dropboxusercontent.com/s/18c6c0uymlw6lab/Souqh_video_Type1_HD02.mp4"
                // src="/images/Souqh_video_Type1_HD03.mp4"
                src="youtu.be/mHEwtXm70vw"
                preload="auto"
                controls
                poster="/images/0IXGLLk0.png"
                // autoPlay
                crossorigin
                muted
                style={{
                  width: lgUp ? 550 : "100%",
                  height: lgUp ? 382 : mdUp ? 459 : 201,
                }}
              >
                Video not supported
              </video> */}

              {/* <iframe
                style={{
                  width: lgUp ? 550 : "100%",
                  height: lgUp ? 382 : mdUp ? 459 : 201,
                }}
                src="https://www.youtube-nocookie.com/embed/mHEwtXm70vw?autoplay=1&rel=0&mute=1&modestbranding=1&loop=0"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              ></iframe> */}

              <iframe
                style={{
                  width: lgUp ? 550 : "100%",
                  height: lgUp ? 382 : mdUp ? 459 : 201,
                }}
                src="https://www.youtube.com/embed/QwEeuaXMxWU?autoplay=1&rel=0&mute=1&modestbranding=1&loop=0"
                title="Souqh - Ditch the stress in your home buying and ownership journey"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              ></iframe>

              {/* <iframe
                src="https://www.youtube.com/embed/Z13-yP3Zhns?modestbranding=1"
                // src="https://www.youtube.com/embed/mHEwtXm70vw?autoplay=1&mute=1&modestbranding=1&controls=0&fs=0&iv_load_policy=3"
                style={{
                  width: lgUp ? 550 : "100%",
                  height: lgUp ? 382 : mdUp ? 459 : 201,
                }}
                sandbox="allow-scripts allow-same-origin"

                // allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              ></iframe> */}
            </Grid>
          </Grid>
        </Container>
      </Container>
    </Grid>
  );
}

export default HomeBuyingFuture;
