import React, { useEffect } from "react";
import { useIntercom } from "react-use-intercom";

function WebsiteIntercomWrapper({ children }) {
  const { boot, shutdown } = useIntercom();

  useEffect(() => {
    boot();
  }, []);
  return children;
}

export default WebsiteIntercomWrapper;
