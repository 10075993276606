import React from "react";
import { Chip, Grid } from "@material-ui/core";
import WebStyles from "../../WebStyles.module.css";
import clsx from "clsx";
import { useExplore } from "souqh-react-redux-hooks/homeBuyer/useExplore";
import { WEBSITE_DOMAIN_URL } from "../websiteConstants";

export default function BusinessFeatureItem({
  mdUp,
  smUp,
  lgUp,
  lgDown,
  xlUp,
  businessFeature,
  isEven,
  index,
}) {
  const height = lgUp ? 420 : 360;
  const radius = height / 2;

  const { formSaveCategoryObjAndCallApi } = useExplore();

  let businessTypes = [
    // {
    //   id: 39,
    //   name: "Plumbing",
    //   shortName: "Plumbing",
    //   logoUrl: "Plumbing.svg",
    //   isBusinessType: false,
    //   sequenceNumber: 8,
    //   displayName: "Plumber",
    //   storefrontPathName: "plumbing-services",
    // },
    // {
    //   id: 21,
    //   name: "Builder",
    //   shortName: "Builder",
    //   logoUrl: "Building.svg",
    //   isBusinessType: false,
    //   sequenceNumber: 11,
    //   storefrontPathName: "builder-services",
    // },
    {
      id: 6,
      name: "Mover",
      shortName: "Moving",
      logoUrl: "Movers.svg",
      isBusinessType: true,
      sequenceNumber: 4,
      displayName: "Movers",
      storefrontPathName: "mover",
    },
    {
      id: 22,
      name: "Cleaning Services",
      shortName: "Cleaning",
      logoUrl: "Cleaningservices.svg",
      isBusinessType: false,
      sequenceNumber: 6,
      displayName: "Cleaners",
      storefrontPathName: "cleaning-services",
    },
    {
      id: 49,
      name: "Storage",
      shortName: "Storage",
      logoUrl: "StorageSolutions.svg",
      isBusinessType: false,
      sequenceNumber: 40,
      displayName: "Storage",
      storefrontPathName: "storage",
    },
  ];


  let businessTypes2 = [
    {
      id: 32,
      name: "Handyman Services",
      shortName: "Handyman",
      logoUrl: "Handymanservices.svg",
      isBusinessType: false,
      sequenceNumber: 9,
      displayName: "Handyman",
      storefrontPathName: "handyman-services",
    },
    {
      id: 37,
      name: "Painters & Decorators",
      shortName: "Painting",
      logoUrl: "Painters&decorators.svg",
      isBusinessType: false,
      sequenceNumber: 14,
      displayName: "Painter",
      storefrontPathName: "painters",
    },
    {
      id: 19,
      name: "Bathroom Remodeling",
      shortName: "Bathroom",
      logoUrl: "BathroomRemodelling.svg",
      isBusinessType: false,
      sequenceNumber: 20,
      displayName: "Bathroom",
      storefrontPathName: "bathroom-remodeling-services",
    },
    {
      id: 35,
      name: "Kitchen Remodeling",
      shortName: "Kitchen",
      logoUrl: "KitchenRemodelling.svg",
      isBusinessType: false,
      sequenceNumber: 29,
      displayName: "Kitchen",
      storefrontPathName: "Kitchen-remodeling",
    },
    {
      id: 53,
      name: "Basements",
      shortName: "Basements",
      logoUrl: "Basements.svg",
      isBusinessType: false,
      sequenceNumber: 43,
      displayName: "Basement",
      storefrontPathName: "basements",
    },
  ];

  return (
    <Grid
      item
      container
      xs={12}
      style={{
        flexDirection: isEven ? "row-reverse" : "row",
        justifyContent: "flex-end",
        marginBottom: lgDown ? 24 : 0,
        position: "relative",
        zIndex: 1,
      }}
    >
      {index === 0 && smUp ? (
        <div
          style={{
            position: "absolute",
            backgroundColor: "#72cac9",
            height: height,
            right: -2000,
            left: lgUp ? "-15%" : "-35%",
            bottom: 0,
            top: lgUp ? "-24%" : "-30%",
            zIndex: -1,
            borderTopLeftRadius: lgUp ? radius : "0px",
            borderBottomLeftRadius: lgUp ? radius : "0px",
            transform: "rotate(-10deg)",
            transformOrigin: "center",
            opacity: 0.25,
          }}
        />
      ) : null}

      {index === 2 && smUp ? (
        <div
          style={{
            position: "absolute",
            backgroundColor: "#F37C61",
            height: height,
            left: -2000,
            right: lgUp ? "-10%" : "-35%",
            bottom: 0,
            top: lgUp ? "-25%" : "-5%",
            zIndex: -1,
            borderTopRightRadius: lgUp ? radius : "0px",
            borderBottomRightRadius: lgUp ? radius : "0px",
            transform: "rotate(10deg)",
            transformOrigin: "center",
            opacity: 0.2,
          }}
        />
      ) : null}
      <Grid
        item
        container
        xs={6}
        style={{
          paddingRight: 32,
        }}
        justifyContent="flex-start"
        alignContent="center"
      >
        <Grid
          className={clsx(WebStyles.hankrndFont)}
          style={{
            fontSize: mdUp ? 40 : 20,
            lineHeight: mdUp ? "48px" : "24px",
            textAlign: "left",
            marginBottom: mdUp ? 12 : 8,
            color: businessFeature.color,
            fontWeight: 600,
          }}
        >
          {businessFeature.name}
        </Grid>
        <Grid
          className={clsx(WebStyles.paraBlackText, WebStyles.nunitoFont)}
          style={{
            fontSize: mdUp ? 28 : 14,
            lineHeight: mdUp ? "38px" : "19px",
            textAlign: "left",
            marginBottom: mdUp ? 12 : 8,
          }}
        >
          {businessFeature.description}
        </Grid>
        {(businessFeature.name === "Schedule your Move" || businessFeature.name === "Simplify Home Improvement Projects") && (
          <>
            {/* <Grid
              item
              className={clsx(WebStyles.paraBlackText, WebStyles.nunitoFont)}
              style={{
                fontSize: mdUp ? 28 : 14,
                lineHeight: mdUp ? "32px" : "16px",
                textAlign: "left",
                marginBottom: mdUp ? 24 : 16,
              }}
            >
              From moving to improving and everything in between, we help you
              find the experts to get the job done!
            </Grid> */}

            <Grid
              className={clsx(WebStyles.paraBlackText, WebStyles.nunitoFont)}
              style={{
                lineHeight: mdUp ? "32px" : "16px",
                textAlign: "left",
                marginBottom: mdUp ? 12 : 8,
              }}
              item
              container
            >
              {businessFeature.name === "Schedule your Move" && businessTypes.map((type) => (
                <Chip
                  style={{
                    backgroundColor: "rgb(243,124,97,0.2)",
                    textTransform: "none",
                    fontFamily: "'Barlow', 'sans-serif'",
                    color: "#F37C61",
                    marginBottom: xlUp ? 0 : lgUp ? 10 : 6,
                    marginRight: 10,
                    fontSize: mdUp ? 16 : 8,
                  }}
                  label={type.displayName || type.shortName}
                  onClick={() => {
                    formSaveCategoryObjAndCallApi(type);
                    window.open(
                      `https://${WEBSITE_DOMAIN_URL}/marketplace/${type.storefrontPathName}`
                    );
                  }}
                />
              ))}
              {businessFeature.name === "Simplify Home Improvement Projects" && businessTypes2.map((type) => (
                <Chip
                  style={{
                    backgroundColor: "rgb(243,124,97,0.2)",
                    textTransform: "none",
                    fontFamily: "'Barlow', 'sans-serif'",
                    color: "#F37C61",
                    marginBottom: xlUp ? 0 : lgUp ? 10 : 6,
                    marginRight: 10,
                    fontSize: mdUp ? 16 : 8,
                  }}
                  label={type.displayName || type.shortName}
                  onClick={() => {
                    formSaveCategoryObjAndCallApi(type);
                    window.open(
                      `https://${WEBSITE_DOMAIN_URL}/marketplace/${type.storefrontPathName}`
                    );
                  }}
                />
              ))}
              <Chip
                style={{
                  backgroundColor: "#F37C61",
                  color: "#FFFFFF",
                  textTransform: "none",
                  marginRight: 10,
                  fontSize: mdUp ? 16 : 8,
                  fontFamily: "'Barlow', 'sans-serif'",
                  marginBottom: lgDown ? 10 : 0,
                }}
                label="More Service Providers"
                onClick={() => {
                  window.open(
                    `https://${WEBSITE_DOMAIN_URL}/marketplace`
                  );
                }}
              />
            </Grid>
          </>
        )}
      </Grid>
      <Grid item xs={6} style={{ display: "flex", alignItems: "center" }}>
        <img
          style={{
            height: smUp ? 500 : 200,
            maxWidth: "100%",
          }}
          src={businessFeature.image}
          alt={businessFeature.name}
          title={businessFeature.name}
        ></img>
      </Grid>
    </Grid>
  );
}
