import { Card, Container, Grid, Hidden } from "@material-ui/core";
import React from "react";
import WebStyles from "../../WebStyles.module.css";
import clsx from "clsx";
import { Link } from "react-router-dom";

export default function LatestUpdates({ commonPadding, mdUp }) {
  return (
    <Grid
      item
      container
      xs={12}
      style={{
        padding: commonPadding,
        marginBottom: 30,
      }}
    >
      <Container>
        <Container>
          <Grid container spacing={2} xs={12} item>
            <Hidden lgUp>
              <Grid item xs={12} style={{ marginTop: 48 }}>
                <Grid
                  item
                  xs={12}
                  align="left"
                  className={clsx(
                    WebStyles.hankrndFont,
                    WebStyles.blueHeaderTxt
                  )}
                  style={{
                    fontSize: mdUp ? 40 : 20,
                    lineHeight: mdUp ? "56px" : "28px",
                  }}
                >
                  Latest Updates
                </Grid>
                <Grid
                  item
                  xs={12}
                  align="left"
                  className={clsx(
                    WebStyles.nunitoFont,
                    WebStyles.paraBlackText
                  )}
                  style={{
                    fontSize: mdUp ? 28 : 10,
                    // lineHeight: mdUp ? "32px" : "16px",
                  }}
                >
                  Here are some helpful resources for you to get started with
                  Souqh
                </Grid>
              </Grid>
            </Hidden>
            <Hidden mdDown>
              <Grid item lg={6}>
                <Grid item xs={12}>
                  <img
                    style={{ width: 541, height: 468, marginLeft: -300 }}
                    src="/images/home_page/latest_updates.svg"
                    alt="Latest Updates - Souqh"
                    title="Latest Updates - Souqh"
                  />
                </Grid>
                <Grid
                  item
                  align="left"
                  className={clsx(
                    WebStyles.hankrndFont,
                    WebStyles.blueHeaderTxt
                  )}
                  style={{
                    fontSize: 40,
                    lineHeight: "56px",
                    fontWeight: 600,
                    marginTop: 36,
                  }}
                >
                  Latest Updates
                </Grid>
                <Grid
                  item
                  align="left"
                  className={clsx(
                    WebStyles.nunitoFont,
                    WebStyles.paraBlackText
                  )}
                  style={{ fontSize: 20, marginTop: 16 }}
                >
                  Here is what we've been upto
                </Grid>
              </Grid>
            </Hidden>
            <Grid container item lg={3} md={6} xs={6} alignContent="flex-end">
              <Link
                to={{
                  pathname:
                    "https://www.einpresswire.com/article/654471426/orea-and-souqh-unveil-new-affinity-member-benefit-program-elevating-realtor-services-for-enhanced-homeownership",
                }}
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <Card
                  elevation={0}
                  style={{
                    border: "1px solid #E2E2E2",
                    borderRadius: 4,
                  }}
                >
                  <Grid
                    item
                    style={{ padding: 30, backgroundColor: "#72CAC9" }}
                  >
                    <img
                      style={{
                        width: mdUp ? 140 : 70,
                        height: mdUp ? 140 : 70,
                      }}
                      src="/images/home_page/Article.svg"
                      alt="Read Article"
                      title="Read Article"
                    />
                  </Grid>
                  <Grid item style={{ padding: "30px 20px" }}>
                    <Grid
                      item
                      align="left"
                      style={{
                        fontSize: mdUp ? 12 : 7,
                        lineHeight: mdUp ? "17px" : "8px",
                      }}
                      className={clsx(
                        WebStyles.hankrndFont,
                        WebStyles.paraBlackText
                      )}
                    >
                      8th September, 2023
                    </Grid>
                    <Grid
                      item
                      align="left"
                      className={clsx(
                        WebStyles.hankrndFont,
                        WebStyles.paraBlackText,
                        WebStyles.sqEllipsisTwoLines
                      )}
                      style={{
                        fontSize: mdUp ? 16 : 12,
                        lineHeight: mdUp ? "25px" : "17.5px",
                        marginTop: 5,
                        fontWeight: 600,
                        wordBreak: "break-word"
                      }}
                    >
                      OREA and Souqh Unveil New Affinity Member Benefit Program, Elevating Realtor Services for Enhanced Homeownership
                    </Grid>
                  </Grid>
                </Card>
              </Link>
            </Grid>
            <Grid container item lg={3} md={6} xs={6} alignContent="flex-end">
              <Link
                to={{
                  pathname:
                    "https://www.newswire.ca/news-releases/souqh-launches-marketplace-platform-to-streamline-collaboration-across-fragmented-real-estate-and-home-services-professionals-simplifying-the-home-ownership-experience-for-canadians-822905751.html",
                }}
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <Card
                  elevation={0}
                  style={{
                    border: "1px solid #E2E2E2",
                    borderRadius: 4,
                  }}
                >
                  <Grid
                    item
                    style={{ padding: 30, backgroundColor: "#72CAC9" }}
                  >
                    <img
                      style={{
                        width: mdUp ? 140 : 70,
                        height: mdUp ? 140 : 70,
                      }}
                      src="/images/home_page/Article.svg"
                      alt="Read Article"
                      title="Read Article"
                    />
                  </Grid>
                  <Grid item align="left" style={{ padding: "30px 20px" }}>
                    <Grid
                      className={clsx(
                        WebStyles.hankrndFont,
                        WebStyles.paraBlackText
                      )}
                      style={{
                        fontSize: mdUp ? 12 : 7,
                        lineHeight: mdUp ? "17px" : "8px",
                      }}
                      item
                    >
                      18th January, 2023
                    </Grid>
                    <Grid
                      style={{
                        fontSize: mdUp ? 16 : 12,
                        lineHeight: mdUp ? "25px" : "17.5px",
                        marginTop: 5,
                        fontWeight: 600,
                        wordBreak: "break-word"
                      }}
                      className={clsx(
                        WebStyles.hankrndFont,
                        WebStyles.paraBlackText,
                        WebStyles.sqEllipsisTwoLines
                      )}
                      item
                      align="left"
                    >
                      Souqh launches marketplace platform to streamline collaboration across fragmented real estate and home services professionals, simplifying the home ownership experience for Canadians
                    </Grid>
                  </Grid>
                </Card>
              </Link>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </Grid>
  );
}
