import {
  Grid,
  Typography,
  Link,
  useMediaQuery,
  useTheme,
  ButtonBase,
  Box,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useExplore } from "souqh-react-redux-hooks/homeBuyer/useExplore";
import WebStyles from "../WebStyles.module.css";
import clsx from "clsx";
import { WEBSITE_DOMAIN_URL } from "./websiteConstants";
import { useProvinces } from "souqh-react-redux-hooks/useProvinces";

const useStyles = makeStyles((theme) => ({
  bodyBackgroundImg: {
    backgroundImage:
      "url('/images/Explore-Section/blue_bg_shape.svg'), url('/images/Explore-Section/orange_shape.svg')",
    backgroundRepeat: "no-repeat, no-repeat",
    backgroundPosition: isMobileDevice() ? "0% 10%,100% 30%" : "0% 5%,100% 97%",
    backgroundSize: isMobileDevice() ? "18%, 12%" : "8%, auto",
    top: 0,
    overflow: "auto",
  },
}));

const isMobileDevice = () => {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

const isTabletDevice = () => {
  let check = false;
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    check = true;
  }

  return check;
};

export default function CategoriesPage() {
  let { city } = useParams();
  const { provinces } = useProvinces(1, true);
  const classes = useStyles();
  const { getServicesList, services } = useExplore();
  const history = useHistory();
  const theme = useTheme();
  const lgUp = useMediaQuery(theme.breakpoints.up("lg"));
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const xlUp = useMediaQuery(theme.breakpoints.up("xl"));

  // const clickedCityDetails = history.location.state;

  useEffect(() => {
    getServicesList();
    window.scrollTo(0, 0);
  }, []);

  // let clickedCity = clickedCityDetails.clickedCity;

  const commonPadding = lgUp ? "0px 4%" : "0px 2%";

  const result =
    provinces &&
    provinces.length &&
    provinces.filter((element) =>
      element.cityDTO.some((subElement) => subElement.shortName === city)
    );
  let provinceName = result && result.length && result[0].name;

  return (
    <div
      style={{ padding: commonPadding }}
      className={
        !isMobileDevice() &&
        !isTabletDevice() &&
        clsx(classes.bodyBackgroundImg)
      }
    >
      <Grid
        className={clsx(WebStyles.samonHeaderTxt, WebStyles.hankrndFont)}
        style={{
          marginTop: 40,
          fontSize: xlUp ? 80 : mdUp ? 40 : 24,
          lineHeight: mdUp ? "74px" : "32px",
          fontWeight: 600,
        }}
      >
        Service Providers in {city}, {provinceName}
      </Grid>
      <Grid
        className={clsx(WebStyles.navyBlueColor, WebStyles.barlowFont)}
        align="left"
        style={{
          marginTop: 40,
          textAlign: "center",
          fontSize: mdUp ? 20 : 14,
          // lineHeight: mdUp ? "38px" : "19px",
          padding: mdUp && "0px 44px",
        }}
      >
        Looking for a service provider in {city}, {provinceName}? Souqh has
        hundreds of trusted real estate and home services professionals to help
        you with every stage of our home ownership journey.
      </Grid>
      <Grid
        container
        style={{
          // border: "1px solid #00000033",
          paddingTop: 36,
          // backgroundColor: "lightgray",
          borderBottom: "1px solid #00000033",
        }}
      >
        {services.map((el, index) => {
          return (
            <Grid
              item
              xs={6}
              md={3}
              lg={2}
              style={{ marginBottom: 28 }}
              key={index}
            >
              <ButtonBase
                href={`https://${WEBSITE_DOMAIN_URL}/marketplace/${el.storefrontPathName}/${city}`}
                target="_blank"
                // onClick={() => {
                //   window.open(
                //     `https://${WEBSITE_DOMAIN_URL}/marketplace/${el.storefrontPathName}/${city}`
                //   );
                // }}
              >
                <Grid
                  item
                  style={{
                    width: 75,
                    height: 75,
                    borderRadius: 8,
                    padding: 10,
                    border: "1px solid #0e1c4e",
                    marginRight: isMobileDevice() && 8,
                  }}
                >
                  <img
                    style={{ width: 50, height: 50 }}
                    src={`/images/Explore-Category/${el.logoUrl}`}
                    alt={el.name}
                    title={el.name}
                  />
                </Grid>
              </ButtonBase>
              <Grid item>
                <Grid
                  className={clsx(
                    WebStyles.navyBlueColor,
                    WebStyles.barlowFont
                  )}
                  align="left"
                  style={{
                    fontSize: 14,
                    textAlign: "center",
                  }}
                >
                  <Box m={1}>{el.shortName}</Box>
                </Grid>
              </Grid>
              {/* <Link
                className={WebStyles.link}
                // to={`${el.name}/categories`}
                href={`https://app.souqh.ca/marketplace/${el.storefrontPathName}/${clickedCity}`}
                target="_blank"
              >
                {el.name}
              </Link> */}
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}
