import React from "react";
import {
  Container,
  Divider,
  Grid,
  Hidden,
  Typography,
  useMediaQuery,
  useTheme,
  Link as MaterialLink,
} from "@material-ui/core";
import { Link, useHistory, useLocation } from "react-router-dom";
import WebStyles from "../WebStyles.module.css";
import { WEBSITE_DOMAIN_URL } from "./websiteConstants";

const isMobileDevice = () => {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

const HBLinks = () => {
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <Grid
      container
      item
      sm={3}
      xs={4}
      direction="column"
      justifyContent="flex-start"
      alignItems={mdUp ? "flex-start" : "center"}
      style={{ paddingLeft: mdUp && 80 }}
    >
      {/* <Link
        style={{
          textDecoration: "none",
          fontSize: mdUp ? 16 : 8,
          color: "#2B3350",
          fontWeight: "bold",
          cursor: "text",
        }}
        // to="/"
      > */}
      <Grid
        className={WebStyles.nunitoFont}
        style={{
          fontSize: mdUp ? 20 : 10,
          textDecoration: "none",
          color: "#2B3350",
          fontWeight: "bold",
          cursor: "text",
        }}
      >
        Home Buyers/Owners
      </Grid>
      {/* </Link> */}
      <Link
        style={{
          textDecoration: "none",
          fontSize: mdUp ? 16 : 8,
          color: "#2B3350",
        }}
        to="/home-buyers"
      >
        <Grid className={WebStyles.nunitoFont}>Home Buyers/Owners</Grid>
      </Link>
      <MaterialLink
        style={{
          textDecoration: "none",
          fontSize: mdUp ? 16 : 8,
          color: "#2B3350",
        }}
        href={`https://${WEBSITE_DOMAIN_URL}/marketplace`}
      >
        <Grid className={WebStyles.nunitoFont}>Marketplace</Grid>
      </MaterialLink>
      <MaterialLink
        style={{
          textDecoration: "none",
          fontSize: mdUp ? 16 : 8,
          color: "#2B3350",
        }}
        href={`https://${WEBSITE_DOMAIN_URL}/userlogin`}
      >
        <Grid className={WebStyles.nunitoFont}>Login</Grid>
      </MaterialLink>
      <MaterialLink
        style={{
          textDecoration: "none",
          fontSize: mdUp ? 16 : 8,
          color: "#2B3350",
        }}
        href={`https://${WEBSITE_DOMAIN_URL}/homebuyersignup`}
      >
        <Grid className={WebStyles.nunitoFont}>Sign Up</Grid>
      </MaterialLink>
      <MaterialLink
        style={{
          textDecoration: "none",
          fontSize: mdUp ? 16 : 8,
          color: "#2B3350",
        }}
        href="https://blog.souqh.ca/category/home-buyers/"
        target={"_blank"}
      >
        <Grid className={WebStyles.nunitoFont}>Blog</Grid>
      </MaterialLink>
    </Grid>
  );
};

const SPLinks = () => {
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));

  const history = useHistory();
  const { pathname } = useLocation();

  let cityName = pathname.includes("categories")
    ? pathname.split("/")[1]
    : null;

  let categoryRoute = !cityName
    ? `/toronto/categories`
    : `/${cityName}/categories`;
  return (
    <Grid
      container
      item
      sm={3}
      xs={4}
      direction="column"
      justifyContent="flex-start"
      alignItems={mdUp ? "flex-start" : "center"}
      style={{ paddingLeft: mdUp ? 94 : 20 }}
    >
      {/* <Link
        style={{
          textDecoration: "none",
          fontSize: mdUp ? 16 : 8,
          color: "#2B3350",
          fontWeight: "bold",
          cursor: "text",
        }}
        // to="/"
      > */}
      <Grid
        className={WebStyles.nunitoFont}
        style={{
          fontSize: mdUp ? 20 : 10,
          textDecoration: "none",
          color: "#2B3350",
          fontWeight: "bold",
          cursor: "text",
        }}
      >
        Service Providers
      </Grid>
      {/* </Link> */}
      <Link
        style={{
          textDecoration: "none",
          fontSize: mdUp ? 16 : 8,
          color: "#2B3350",
        }}
        to="/service-providers"
      >
        <Grid className={WebStyles.nunitoFont}>Service Providers</Grid>
      </Link>
      <Link
        style={{
          textDecoration: "none",
          fontSize: mdUp ? 16 : 8,
          color: "#2B3350",
          cursor: "pointer",
        }}
        to={categoryRoute}
        // onClick={() => {
        //   if (!cityName) {
        //     history.push({
        //       pathname: `toronto/categories`,
        //       state: {
        //         clickedCity: "Toronto", //city defaulted to Toronto here
        //         clickedProvince: "Ontario",
        //       },
        //     });
        //   } else {
        //     window.location.reload();
        //   }
        // }}
      >
        <Grid className={WebStyles.nunitoFont}>Categories</Grid>
      </Link>
      <MaterialLink
        style={{
          textDecoration: "none",
          fontSize: mdUp ? 16 : 8,
          color: "#2B3350",
        }}
        href={`https://${WEBSITE_DOMAIN_URL}/login`}
      >
        <Grid className={WebStyles.nunitoFont}>Login</Grid>
      </MaterialLink>
      <MaterialLink
        style={{
          textDecoration: "none",
          fontSize: mdUp ? 16 : 8,
          color: "#2B3350",
        }}
        href={`https://${WEBSITE_DOMAIN_URL}/new`}
      >
        <Grid className={WebStyles.nunitoFont}>Sign Up</Grid>
      </MaterialLink>
      <MaterialLink
        style={{
          textDecoration: "none",
          fontSize: mdUp ? 16 : 8,
          color: "#2B3350",
        }}
        href="https://blog.souqh.ca/category/service-providers/"
        target={"_blank"}
      >
        <Grid className={WebStyles.nunitoFont}>Blog</Grid>
      </MaterialLink>
    </Grid>
  );
};

const SouqhLinks = () => {
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Grid
      container
      item
      sm={3}
      xs={4}
      direction="column"
      justifyContent="flex-start"
      alignItems={mdUp ? "flex-start" : "center"}
      style={{ paddingLeft: mdUp ? 80 : 20 }}
    >
      {/* <Link
        style={{
          textDecoration: "none",
          fontSize: mdUp ? 16 : 8,
          color: "#2B3350",
          fontWeight: "bold",
          cursor: "text",
        }}
        // to="/"
      > */}
      <Grid
        className={WebStyles.nunitoFont}
        style={{
          fontSize: mdUp ? 20 : 10,
          textDecoration: "none",
          color: "#2B3350",
          fontWeight: "bold",
          cursor: "text",
        }}
      >
        Souqh
      </Grid>
      {/* </Link> */}
      <Link
        style={{
          textDecoration: "none",
          fontSize: mdUp ? 16 : 8,
          color: "#2B3350",
        }}
        to="/about-us"
      >
        <Grid className={WebStyles.nunitoFont}>About Us</Grid>
      </Link>
      <Link
        style={{
          textDecoration: "none",
          fontSize: mdUp ? 16 : 8,
          color: "#2B3350",
        }}
        to="/contact-us"
      >
        <Grid className={WebStyles.nunitoFont}>Contact Us</Grid>
      </Link>
      <Link
        style={{
          textDecoration: "none",
          fontSize: mdUp ? 16 : 8,
          color: "#2B3350",
        }}
        to="/cities"
      >
        <Grid className={WebStyles.nunitoFont}>Cities</Grid>
      </Link>
      {/* <MaterialLink
        style={{
          textDecoration: "none",
          fontSize: mdUp ? 16 : 8,
          color: "#2B3350",
          cursor: "pointer",
        }}
        onClick={() => {
          if (!cityName) {
            history.push({
              pathname: `Toronto/categories`,
              state: {
                clickedCity: "Toronto", //city defaulted to Toronto here
                clickedProvince: "Ontario",
              },
            });
          } else {
            window.location.reload();
          }
        }}
      >
        <Grid className={WebStyles.nunitoFont}>Categories</Grid>
      </MaterialLink> */}
      <Link
        style={{
          textDecoration: "none",
          fontSize: mdUp ? 16 : 8,
          color: "#2B3350",
        }}
        to="/marketplace-widget"
      >
        <Grid className={WebStyles.nunitoFont}>Marketplace Widget</Grid>
      </Link>
      <Grid
        container
        style={{
          width: isMobileDevice() && "136px",
          marginLeft: isMobileDevice() && -26,
          justifyContent: isMobileDevice() && "center",
        }}
      >
        <MaterialLink
          style={{
            textDecoration: "none",
            fontSize: mdUp ? 16 : 8,
            color: "#2B3350",
          }}
          href={`https://${WEBSITE_DOMAIN_URL}/termsAndPolicy`}
          target={"_blank"}
        >
          <Grid className={WebStyles.nunitoFont}>Terms of Use</Grid>
        </MaterialLink>
        <Grid className={WebStyles.nunitoFont}>&nbsp;|&nbsp;</Grid>
        <MaterialLink
          style={{
            textDecoration: "none",
            fontSize: mdUp ? 16 : 8,
            color: "#2B3350",
          }}
          href={`https://${WEBSITE_DOMAIN_URL}/privacypolicy`}
          target={"_blank"}
        >
          <Grid className={WebStyles.nunitoFont}>Privacy Policy</Grid>
        </MaterialLink>
      </Grid>
    </Grid>
  );
};

const ContactUsLinks = () => {
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const lgUp = useMediaQuery(theme.breakpoints.up("lg"));
  return (
    <Grid item sm={3} xs={3} container>
      <Grid item xs={12} container>
        <Link
          style={{
            textDecoration: "none",
            // fontSize: mdUp ? 16 : 8,
            color: "#2B3350",
            fontWeight: "bold",
          }}
          to="/contact-us"
        >
          <Grid
            style={{ fontSize: mdUp ? 20 : 10, fontWeight: "bold" }}
            className={WebStyles.nunitoFont}
          >
            Contact Us
          </Grid>
        </Link>
      </Grid>
      <Grid
        item
        xs={12}
        container
        style={{ fontSize: mdUp ? 16 : 8, color: "#2B3350" }}
        className={WebStyles.nunitoFont}
      >
        <a
          style={{ color: "#2B3350", textDecoration: "none" }}
          href="tel:+1 (647) 871 3786"
        >
          +1 (647) 871 3786
        </a>
      </Grid>
      <Grid
        item
        xs={12}
        container
        style={{ fontSize: mdUp ? 16 : 8, color: "#2B3350" }}
        className={WebStyles.nunitoFont}
      >
        <a
          style={{ color: "#2B3350", textDecoration: "none" }}
          href="mailto:hello@souqh.ca"
        >
          hello@souqh.ca
        </a>
      </Grid>
      <Grid
        item
        xs={12}
        container
        style={{ fontSize: mdUp ? 16 : 8, color: "#2B3350" }}
        className={WebStyles.nunitoFont}
      >
        Toronto, Ontario
      </Grid>

      <Grid sm={8} xs={12} item container style={{ marginTop: 5 }} spacing={2}>
        <Grid md={2} xs={2} item>
          <Link
            to={{
              pathname: "https://www.facebook.com/Souqh-105815918284357",
            }}
            target="_blank"
          >
            <img
              src="images/footer/facebook.svg"
              className={
                lgUp ? WebStyles.socialIcon : WebStyles.socialIconMobile
              }
              alt="Facebook Social Icon"
            />
          </Link>
        </Grid>
        <Grid md={2} xs={2} item>
          <Link
            to={{
              pathname: "https://www.instagram.com/souqhteam/",
            }}
            target="_blank"
          >
            <img
              src="images/footer/Instagram.svg"
              className={
                lgUp ? WebStyles.socialIcon : WebStyles.socialIconMobile
              }
              style={{ marginLeft: 4 }}
              alt="Instagram Social Icon"
            />
          </Link>
        </Grid>
        <Grid md={2} xs={2} item>
          <Link
            to={{
              pathname: "https://twitter.com/souqhteam",
            }}
            target="_blank"
          >
            <img
              src="images/footer/Twitter.svg"
              className={
                lgUp ? WebStyles.socialIcon : WebStyles.socialIconMobile
              }
              style={{ marginLeft: 8 }}
              alt="Twitter Social Icon"
            />
          </Link>
        </Grid>
        <Grid md={2} xs={2} item>
          <Link
            to={{
              pathname: "https://www.linkedin.com/company/souqh/",
            }}
            target="_blank"
          >
            <img
              src="images/footer/LinkedIn.svg"
              className={
                lgUp ? WebStyles.socialIcon : WebStyles.socialIconMobile
              }
              style={{ marginLeft: 12 }}
              alt="LinkedIn Social Icon"
            />
          </Link>
        </Grid>
        <Grid md={2} xs={2} item>
          <Link
            to={{
              pathname:
                "https://www.youtube.com/channel/UClH1brV9v85OAt32B5_uLTg",
            }}
            target="_blank"
          >
            <img
              src="images/footer/youtube.svg"
              className={
                lgUp ? WebStyles.socialIcon : WebStyles.socialIconMobile
              }
              style={{ marginLeft: 16 }}
              alt="Youtube Social Icon"
            />
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
};

function Footer() {
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));

  let year = new Date().getFullYear();

  return (
    <Container>
      <Container style={{ padding: smDown && 0 }}>
        <Grid
          container
          style={{
            // paddingRight: smDown ? 40 : 0,
            marginTop: 20,
            lineHeight: "34px",
          }}
        >
          <Grid
            item
            sm={3}
            // xs={12}
            xs={12}
            container
            justifyContent="center"
            alignContent="center"
          >
            <Link to="/">
              <img
                style={{
                  height: mdUp ? 85 : 50,
                  width: mdUp ? 230 : 125,
                  marginTop: mdUp ? 20 : 10,
                  marginLeft: mdUp ? 20 : 0,
                }}
                // src="/images/footer/souqh_logo_withName.svg"
                src="/images/souqh logo tagline.svg"
                alt="Souqh Logo"
                title="Souqh Logo"
              />
            </Link>
          </Grid>
          {/* <Hidden smUp>
            <Grid xs={1}></Grid>
          </Hidden> */}
          <HBLinks></HBLinks>
          <SPLinks></SPLinks>
          <SouqhLinks></SouqhLinks>
          <Hidden smUp>
            <Grid xs={2}></Grid>
          </Hidden>

          <Divider
            orientation="horizontal"
            // variant="fullWidth"
            style={{ width: "100%", margin: "10px 0", marginTop: 50 }}
          />

          <Grid container justifyContent="center" style={{ marginBottom: 10 }}>
            <Grid
              className={WebStyles.nunitoFont}
              style={{ fontSize: mdUp ? "15px" : "7.5px", color: "#0e1c4e" }}
            >
              © {year} Souqh Technologies Inc. All Rights Reserved /{" "}
              <Link
                target="_blank"
                style={{ textDecoration: "none", color: "#0e1c4e" }}
                to={{ pathname: `https://${WEBSITE_DOMAIN_URL}/privacypolicy` }}
              >
                Privacy Policy
              </Link>{" "}
              /{" "}
              <Link
                target="_blank"
                style={{ textDecoration: "none", color: "#0e1c4e" }}
                to={{
                  pathname: `https://${WEBSITE_DOMAIN_URL}/termsAndPolicy`,
                }}
              >
                Terms & Conditions
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Grid style={{ height: 80, width: "100%" }}></Grid>
      </Container>
    </Container>
  );
}

export default Footer;
