import React from "react";
import { Container, Grid, makeStyles, Typography } from "@material-ui/core";
import WebStyles from "../../WebStyles.module.css";
import clsx from "clsx";

export default function PricingBullet({ mdUp, point, index, ticks }) {
  return (
    <Grid
      item
      container
      xs={12}
      style={{
        // alignItems: "baseline",
        alignItems: mdUp ? "baseline" : "center",
        marginBottom: mdUp ? 24 : 12,
      }}
    >
      <Grid item xs={1}>
        <Grid
          container
          alignContent="flex-start"
          justifyContent="center"
          // style={{
          //   width: 12,
          //   height: 12,
          //   borderRadius: "50%",
          //   backgroundColor: "#0E1C4E",
          //   opacity: index + 1 > ticks ? 0.5 : 1,
          // }}
        >
          {index + 1 > ticks ? (
            <img
              style={{ height: 16, width: 16 }}
              src="/images/service_providers/Icon metro-cancel.svg"
              alt="metro-cancel"
              title="metro-cancel"
            ></img>
          ) : (
            <img
              style={{ height: 16, width: 16 }}
              src="/images/service_providers/Icon material-check-circle.svg"
              alt="material-check-circle"
              title="material-check-circle"
            ></img>
          )}
        </Grid>
      </Grid>
      <Grid
        item
        xs={11}
        className={clsx(WebStyles.barlowFont, WebStyles.paraBlackText)}
        style={{
          fontSize: mdUp ? 24 : 12,
          lineHeight: mdUp ? "30px" : "15px",
          paddingLeft: 8,
          textAlign: "left",
        }}
      >
        {point}
      </Grid>
    </Grid>
  );
}
