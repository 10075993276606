import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Snackbar from "@material-ui/core/Snackbar";
import {
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@material-ui/core";
import WebStyles from "../../WebStyles.module.css";
import clsx from "clsx";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: "white",
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="small"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "white",
    },
    color: "white",
    marginTop: 15,
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        // borderColor: "red",
        borderColor: "white",
      },
      "&:hover fieldset": {
        // borderColor: "yellow",
        borderColor: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "white",
      },
    },
  },
})(TextField);

const CssFormControl = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "white",
    },
    color: "white",
    // backgroundColor: "white",
    borderColor: "white",

    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiSelect-select": {
      color: "white",
    },
    "& .MuiSelect-iconOutlined": {
      color: "white",
    },
    "& .MuiOutlinedInput-root": {
      borderColor: "white",
      "& fieldset": {
        // borderColor: "red",
        borderColor: "white",
      },
      "&:hover fieldset": {
        // borderColor: "yellow",
        borderColor: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "white",
      },
    },
  },
})(FormControl);

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const serviceProviderOptions = [
  { label: "Real Estate Broker", value: "RealEstateBroker" },
  { label: "Mortgage Broker", value: "MortgageBroker" },
  { label: "Real Estate Lawyer", value: "RealEstateLawyer" },
  { label: "Property Inspector", value: "PropertyInspector" },
  { label: "Property Appraiser", value: "PropertyAppraiser" },
  { label: "Mover", value: "Mover" },
  {
    label: "General Contractor (Electrician, Plumber, Landscaper, etc.)",
    value: "GeneralContractor",
  },
  { label: "Other", value: "Other" },
];
const homeBuyerOptions = [
  { label: "Home Buyer", value: "homeBuyer" },
  { label: "Home Owner", value: "homeOwner" },
];

export const postData = async (url = "", data = {}) => {
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  return response.json();
};

export default function HomeWebForm({ open, who, handleClose }) {
  const [options, setOptions] = useState([]);
  const [inProgress, setinProgress] = useState(false);

  const [radioValue, setradioValue] = useState(who || "serviceProvider");
  const [option, setOption] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [city, setCity] = useState("");
  const [hearAboutUs, setHearAboutUs] = useState("");
  const [showFeedback, setshowFeedback] = useState(false);

  useEffect(() => {
    who && setradioValue(who);
  }, [who]);

  useEffect(() => {
    setOption("");
    radioValue && radioValue === "homeBuyer" && setOptions(homeBuyerOptions);
    radioValue &&
      radioValue === "serviceProvider" &&
      setOptions(serviceProviderOptions);
  }, [radioValue]);

  return (
    <>
      <Snackbar
        open={showFeedback}
        autoHideDuration={6000}
        onClose={() => {
          setshowFeedback(false);
        }}
        ContentProps={{ style: { backgroundColor: "#1b1e4c", minWidth: 0 } }}
        // style={{ display: "flex" }}
        message={
          <Grid container justifyContent="center" item xs={12}>
            Thank you for joining our waitlist
          </Grid>
        }
      ></Snackbar>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        PaperProps={{
          style: {
            backgroundColor: "#1B1E4C",
            borderRadius: 8,
          },
        }}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <Grid
            container
            item
            x={12}
            style={{ paddingTop: 30, paddingBottom: 20 }}
            justifyContent="center"
          >
            {/* <img src="/images/header_logo.svg" style={{ width: 175 }} /> */}
            <img
              src="/images/Souqh w logo tagline.svg"
              alt="Souqh Logo"
              title="Souqh Logo"
              //   style={{ width: 175 }}
            />
            {/* <Grid
            container
            justifyContent="center"
            className={clsx(WebStyles.hankrndFont)}
            style={{
              fontSize: 36,
              color: "white",
              fontWeight: "bold",
              //   paddingTop: 10,
            }}
          >
            Welcome to Souqh!
          </Grid> */}
          </Grid>
        </DialogTitle>
        <DialogContent
          className={clsx(WebStyles.barlowFont)}
          style={{
            fontSize: 16,
            color: "white",
            padding: 40,
            paddingTop: 0,
          }}
        >
          <Grid container justifyContent="center">
            <Typography>
              Join our waitlist to get early access as we launch later this year
            </Typography>
          </Grid>
          <Grid container style={{ paddingTop: 10 }}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                const endpoint =
                  radioValue === "homeBuyer"
                    ? "/souqh-waitlist/hb-details"
                    : "/souqh-waitlist/sp-details";
                let payload = {
                  city: city,
                  email: email,
                  fullName: name,
                  source: hearAboutUs,
                };
                if (radioValue === "homeBuyer") {
                  payload.userType = option;
                } else {
                  payload.serviceProviderType = option;
                }
                setinProgress(true);
                postData(
                  process.env.REACT_APP_SOUQH_API_SERVER_BASE_URL + endpoint,
                  payload
                )
                  .then((data) => {
                    setinProgress(false);
                    handleClose();
                    setshowFeedback(true);
                  })
                  .catch((e) => {
                    setinProgress(false);
                    handleClose();
                  });
              }}
              style={{ width: "100%" }}
            >
              <FormControl
                component="fieldset"
                style={{ display: "flex", flexDirection: "row" }}
              >
                <Grid container item sm={2} xs={6} alignContent="center">
                  <Typography
                    style={{
                      fontSize: 16,
                      color: "white",
                    }}
                  >
                    I am a
                  </Typography>
                </Grid>
                <RadioGroup
                  style={{ display: "flex", flexDirection: "row" }}
                  value={radioValue}
                  onChange={(e) => {
                    setradioValue(e.target.value);
                  }}
                >
                  <FormControlLabel
                    value="serviceProvider"
                    control={<Radio style={{ color: "white" }} />}
                    label="Service Provider"
                  />
                  <FormControlLabel
                    value="homeBuyer"
                    control={<Radio style={{ color: "white" }} />}
                    label="Home Buyer"
                  />
                </RadioGroup>
              </FormControl>
              <CssTextField
                margin="dense"
                fullWidth
                InputLabelProps={{ style: { color: "white" } }}
                InputProps={{ style: { color: "white" } }}
                label="Name"
                variant="outlined"
                onChange={(e) => {
                  setName(e.target.value);
                }}
                //   style={{ backgroundColor: "white" }}
              />
              <CssTextField
                margin="dense"
                fullWidth
                InputLabelProps={{ style: { color: "white" } }}
                InputProps={{ style: { color: "white" } }}
                label="Email"
                variant="outlined"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                inputProps={{
                  type: "email",
                  pattern: "[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}$",
                  title:
                    "Plase provide valid email address. e.g. foo@example.com",
                  style: {
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                    borderLeft: "none",
                  },
                }}
                //   style={{ backgroundColor: "white" }}
              />
              <CssFormControl
                variant="outlined"
                // className={classes.formControl}
                style={{ marginTop: 15 }}
                fullWidth
                margin="dense"
              >
                <InputLabel
                  id="demo-simple-select-outlined-label"
                  style={{ color: "white" }}
                >
                  {radioValue === "homeBuyer"
                    ? "User Type"
                    : "Service Provider Type"}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  fullWidth
                  value={option}
                  onChange={(e) => {
                    setOption(e.target.value);
                  }}
                  label={
                    radioValue === "homeBuyer"
                      ? "User Type"
                      : "Service Provider Type"
                  }
                  // input={<CssTextField />}
                >
                  {options.map((e) => {
                    return (
                      <MenuItem key={e.value} value={e.value}>
                        {e.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </CssFormControl>
              <CssTextField
                margin="dense"
                fullWidth
                InputLabelProps={{ style: { color: "white" } }}
                InputProps={{ style: { color: "white" } }}
                label="City"
                variant="outlined"
                //   style={{ backgroundColor: "white" }}
                onChange={(e) => {
                  setCity(e.target.value);
                }}
              />
              <CssTextField
                margin="dense"
                fullWidth
                InputLabelProps={{ style: { color: "white" } }}
                InputProps={{ style: { color: "white" } }}
                label="Where did you hear about us?"
                variant="outlined"
                //   style={{ backgroundColor: "white" }}
                onChange={(e) => {
                  setHearAboutUs(e.target.value);
                }}
              />
              <Grid
                container
                justifyContent="center"
                style={{ paddingTop: 20 }}
              >
                <Button
                  disabled={
                    !radioValue ||
                    !option ||
                    !name ||
                    !email ||
                    !city ||
                    !hearAboutUs ||
                    inProgress
                  }
                  autoFocus
                  // onClick={handleClose}
                  color="primary"
                  type="submit"
                  style={{
                    opacity:
                      !radioValue ||
                      !option ||
                      !name ||
                      !email ||
                      !city ||
                      !hearAboutUs ||
                      inProgress
                        ? 0.5
                        : 1,
                    backgroundColor: "#FA7E61",
                    color: "#0E1C4E",
                    padding: "10px 40px",
                    textTransform: "capitalize",
                  }}
                >
                  Submit
                </Button>
              </Grid>
            </form>
          </Grid>
        </DialogContent>
        {/* <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          Save changes
        </Button>
      </DialogActions> */}
      </Dialog>
    </>
  );
}
