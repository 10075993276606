import React from "react";
import { Container, Grid, Typography } from "@material-ui/core";
import WebStyles from "../../WebStyles.module.css";
import StepBox from "./StepBox";
import clsx from "clsx";
import { WEBSITE_DOMAIN_URL } from "../websiteConstants";

export default function HowItWorks({ mdUp, lgUp, lgDown, commonPadding }) {
  const steps = [
    {
      name: "Register",
      linkText: "Complete",
      link: `https://${WEBSITE_DOMAIN_URL}/new`,
      description: " your profile in minutes and get started",
      image: "/images/service_providers/laptop.svg",
      numImg: "/images/service_providers/one.svg",
    },
    {
      name: "Setup",
      description:
        "Provide details of your products and services and Souqh instantly creates your online storefront, including your brand and product pages",
      image: "/images/service_providers/search.svg",
      numImg: "/images/service_providers/two.svg",
    },
    {
      name: "Launch",
      description:
        "Souqh helps you launch your digital brand, so you can get up and running in minutes!",
      image: "/images/service_providers/hand_cell.svg",
      numImg: "/images/service_providers/three.svg",
    },
  ];
  return (
    <Grid
      item
      container
      xs={12}
      style={{
        padding: commonPadding,
      }}
    >
      <Container>
        <Grid
          item
          xs={12}
          lg={12}
          container
          style={{ marginTop: 92, paddingBottom: 30 }}
        >
          <Grid
            item
            xs={12}
            style={{
              marginBottom: 12,
            }}
          >
            <Grid
              className={clsx(WebStyles.samonHeaderTxt, WebStyles.hankrndFont)}
              style={{
                fontSize: mdUp ? 60 : 30,
                lineHeight: mdUp ? "74px" : "32px",
                textAlign: "left",
                marginBottom: 8,
                fontWeight: 600,
              }}
            >
              How it works
            </Grid>
            <Grid
              className={clsx(WebStyles.paraBlackText, WebStyles.nunitoFont)}
              style={{
                fontSize: mdUp ? 28 : 14,
                lineHeight: mdUp ? "38px" : "19px",
                marginBottom: 24,
                textAlign: "left",
              }}
            >
              Get started in three simple steps
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            container
            style={{
              marginBottom: 16,
            }}
          >
            {steps.map((item, index) => {
              return (
                <Grid
                  item
                  xs={12}
                  md={4}
                  container
                  key={index}
                  style={{
                    marginBottom: 16,
                  }}
                >
                  <StepBox
                    index={index}
                    data={item}
                    mdUp={mdUp}
                    lgUp={lgUp}
                    lgDown={lgDown}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
}
