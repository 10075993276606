import React, { Suspense } from "react";
import {
  Toolbar,
  useMediaQuery,
  useTheme,
  CssBaseline,
  Container,
} from "@material-ui/core";
import Header, {
  APP_BAR_HEIGHT_DESKTOP,
  APP_BAR_HEIGHT_MOBILE,
} from "./Header";
import Footer from "./Footer";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import AboutUsPage from "./aboutUs";
import ContactUsPage from "./contactUs";
import HomePage from "./home";
import HomeBuyersPage from "./homeBuyers";
import ServiceProvidersPage from "./serviceProviders";
import WebsiteIntercomWrapper from "../WebsiteIntercomWrapper";
import MarketPlaceRedirection from "./MarketPlaceRedirection";
import CitiesPage from "./CitiesPage";
import CategoriesPage from "./CategoriesPage";
import MarketplaceWidgetEmbed from "./MarketplaceWidgetEmbed";

export default function MainLayout(props) {
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const xlUp = useMediaQuery(theme.breakpoints.up("xl"));

  return (
    <React.Fragment>
      <Container maxWidth={false} style={{ padding: 0 }}>
        <WebsiteIntercomWrapper>
          <Router>
            <Suspense fallback={<div>Loading...</div>}>
              <CssBaseline />
              <Header></Header>
              <Toolbar
                style={{
                  height: xlUp
                    ? APP_BAR_HEIGHT_DESKTOP
                    : mdUp
                    ? 192
                    : APP_BAR_HEIGHT_MOBILE,
                }}
              />
              <Switch>
                <Route path="/contact-us">
                  <ContactUsPage />
                </Route>
                <Route path="/about-us">
                  <AboutUsPage />
                </Route>
                <Route path="/home-buyers">
                  <HomeBuyersPage />
                </Route>
                <Route path="/service-providers">
                  <ServiceProvidersPage />
                </Route>
                <Route path="/marketplace/:appSearchedService?/:appSearchedCityId?">
                  <MarketPlaceRedirection />
                </Route>
                <Route exact path="/">
                  <HomePage />
                </Route>
                <Route exact path="/cities">
                  <CitiesPage />
                </Route>
                <Route exact path="/:city/categories">
                  <CategoriesPage />
                </Route>
                <Route allowVisit path="/marketplace-widget">
                  <MarketplaceWidgetEmbed />
                </Route>
                <Route path="*" render={() => <Redirect to="/" />}></Route>
              </Switch>
              <Footer></Footer>
            </Suspense>
          </Router>
        </WebsiteIntercomWrapper>
      </Container>
    </React.Fragment>
  );
}
