import React from "react";
import { Container, Grid, Typography } from "@material-ui/core";
import WebStyles from "../../WebStyles.module.css";
import BusinessTypeItem from "./BusinessTypeItem";
import clsx from "clsx";

export default function BusinessTypes({ lgUp, smUp, mdUp, commonPadding }) {
  const servicesRow1 = [
    {
      name: "Realtors",
      image: "/images/service_providers/Realtor.svg",
    },
    {
      name: "Mortgage Brokers",
      image: "/images/service_providers/Mortagage_Broker.svg",
    },
    {
      name: "Contractors",
      image: "/images/service_providers/Contractor.svg",
    },
    {
      name: "Lawyers",
      image: "/images/service_providers/Lawyer.svg",
    },
    {
      name: "Property Inspectors",
      image: "/images/service_providers/Property_inspector.svg",
    },
    {
      name: "Movers",
      image: "/images/service_providers/movers.svg",
    },
  ];

  return (
    <Grid
      item
      container
      xs={12}
      style={{
        padding: commonPadding,
      }}
    >
      <Container>
        <Grid
          item
          xs={12}
          lg={12}
          container
          style={{ paddingBottom: lgUp ? 90 : 40 }}
        >
          <Grid item xs={12} sm={12} md={12} lg={5}>
            <Grid item xs={10}>
              <Grid item xs={12} sm={6} md={6} lg={12}>
                <Grid
                  className={clsx(
                    WebStyles.samonHeaderTxt,
                    WebStyles.hankrndFont
                  )}
                  style={{
                    fontSize: mdUp ? 40 : 30,
                    lineHeight: mdUp ? "48px" : "32px",
                    textAlign: "left",
                    marginBottom: 8,
                    fontWeight: 600,
                  }}
                >
                  {/* Why Souqh? */}
                  What is Souqh?
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <Grid
                  className={clsx(
                    WebStyles.paraBlackText,
                    WebStyles.nunitoFont
                  )}
                  style={{
                    fontSize: mdUp ? 28 : 14,
                    lineHeight: mdUp ? "38px" : "19px",
                    textAlign: "left",
                    marginBottom: mdUp ? 24 : 12,
                  }}
                >
                  {/* Your one-stop connection to leads, referrals, technology and
                  digital marketing. Free up time to provide the best service to
                  your customers and let Souqh do the rest! */}
                  Souqh is a collaboration platform, designed to seamlessly connect and transact with your clients and preferred partners. Using our streamlined, collaborative workspace, you can deliver a personalized experience at every step of the homeowner journey, converting one-time transactions into long-term relationships 
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container xs={12} sm={12} md={12} lg={7}>
            <Grid item xs={12}>
              <Grid
                className={clsx(
                  WebStyles.samonHeaderTxt,
                  WebStyles.hankrndFont
                )}
                style={{
                  fontSize: mdUp ? 40 : 30,
                  lineHeight: mdUp ? "48px" : "32px",
                  // textAlign: "left",
                  marginBottom: 16,
                  fontWeight: 600,
                }}
              >
                Souqh is increasing opportunities for:
              </Grid>
            </Grid>
            <Grid item container xs={12}>
              {servicesRow1.map((item, index) => {
                return (
                  <Grid item container xs={4} key={index}>
                    <BusinessTypeItem smUp={smUp} mdUp={mdUp} data={item} />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
}
