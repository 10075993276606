import { Container, Grid, makeStyles, Paper } from "@material-ui/core";
import React from "react";
import WebStyles from "../../WebStyles.module.css";
import clsx from "clsx";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  buttons: {
    textTransform: "capitalize",
    padding: "0px 30px",
  },
  mainBoxStyle: {
    borderRadius: "4px",
    color: "white",
    padding: "24px",
    backgroundColor: "#66d7d1",
  },
}));

export default function ExperiencePremium({ mdUp, commonPadding }) {
  const classes = useStyles();

  return (
    <Grid
      item
      container
      xs={12}
      style={{
        padding: commonPadding,
        marginTop: 32,
      }}
    >
      <Container>
        <Grid item container style={{ boxShadow: "3px 3px 10px #00000029" }}>
          <Grid item xs={7} className={classes.mainBoxStyle}>
            <Grid
              className={WebStyles.hankrndFont}
              style={{
                fontSize: 50,
                lineHeight: "60px",
                textAlign: "left",
                marginBottom: 20,
                color: "#ffffff",
                fontWeight: 600,
              }}
            >
              Enterprise Pricing
            </Grid>
            <Grid
              className={clsx(WebStyles.paraBlackText, WebStyles.nunitoFont)}
              style={{
                fontSize: 28,
                lineHeight: "32px",
                textAlign: "left",
                marginBottom: mdUp ? 24 : 12,
              }}
            >
              Enterprise plan for teams with more than 10 users.{" "}
              <Link
                style={{
                  // textDecoration: "none",
                  color: "#2b3350",
                }}
                to="/contact-us"
              >
                Get in touch{" "}
              </Link>
              with us.
            </Grid>
          </Grid>
          <Grid item xs={5}>
            <Grid style={{}}>
              <img
                style={{ height: "280px", width: "100%", display: "flex" }}
                src="/images/service_providers/experience_the_premium.svg"
                alt="experience the premium"
                title="experience the premium"
              ></img>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
}
