import {
  Grid,
  Paper,
  Typography,
  Button,
  TextField,
  makeStyles,
  Switch,
  Container,
  useMediaQuery,
  useTheme,
  Divider,
  ImageList,
  ImageListItem,
  Box,
  Checkbox,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import WebStyles from "../../WebStyles.module.css";
import clsx from "clsx";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useExplore } from "souqh-react-redux-hooks/homeBuyer/useExplore";
import GenerateEmbedCodeDialog from "./GenerateEmbedCodeDialog";
import ReCAPTCHA from "react-google-recaptcha";
import { v4 as uuidv4 } from "uuid";
import { useMarketplaceWidget } from "souqh-react-redux-hooks/common/useMarketplaceWidget";
import { cloneDeep } from "lodash";

const useStyles = makeStyles((theme) => ({
  inputs: {
    marginTop: 0,
    marginBottom: 0,
  },

  textInput: {
    "& .MuiInput-underline:before": {
      borderBottom: "none",
    },
  },
  bodyBackgroundImg: {
    backgroundImage:
      "url('/images/Explore-Section/blue_bg_shape.svg'), url('/images/Explore-Section/orange_shape.svg')",
    backgroundRepeat: "no-repeat, no-repeat",
    backgroundPosition: isMobileDevice()
      ? "0% 10%,100% 30%"
      : "0% 13%,100% 95%",
    backgroundSize: isMobileDevice() ? "18%, 12%" : "8%, auto",
    top: 0,
    overflow: "auto",
  },
}));

const isMobileDevice = () => {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

const isTabletDevice = () => {
  let check = false;
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    check = true;
  }

  return check;
};

export default function MarketplaceWidgetEmbed() {
  const classes = useStyles();

  const [showCitiesTextfield, setShowCitiesTextfield] = useState(false);
  const [searchedCity, setSearchedCity] = useState(null);
  const [showCategoriesTextfield, setShowCategoriesTextfield] = useState(false);
  const [searchedCategory, setSearchedCategory] = useState(null);
  const [showAllCategories, setShowAllCategories] = useState(false);
  const [uuid, setUUID] = useState(null);
  const [openGenerateEmbedCodeDialog, setOpenGenerateEmbedCodeDialog] =
    useState(false);
  const [displayCategories, setDisplayCategories] = useState([]);
  const [selectAllCategories, setSelectAllCategories] = useState(false);

  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));

  const [disableSubmit, setDisableSubmit] = useState(true);

  const captchaRef = useRef(null);

  const { cities, getCitiesList, services, getServicesList } = useExplore();
  const { saveSelectedCategories } = useMarketplaceWidget();

  useEffect(() => {
    getServicesList();
    getCitiesList();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (services.length) {
      setDisplayCategories(services);
      const tempList = cloneDeep(services);
      tempList.forEach((item) => {
        item.selected = false;
      });
      setDisplayCategories(tempList);
    }
  }, [services]);

  const handleCheckboxChange = (checked, categoryName) => {
    const tempList = cloneDeep(displayCategories);
    tempList.forEach((item) => {
      if (item.name === categoryName) {
        item.selected = checked;
      }
    });
    setDisplayCategories(tempList);
  };

  useEffect(() => {
    if (displayCategories) {
      const tempList = cloneDeep(displayCategories);
      if (selectAllCategories) {
        tempList.forEach((item) => {
          item.selected = true;
        });
      } else {
        tempList.forEach((item) => {
          item.selected = false;
        });
      }
      setDisplayCategories(tempList);
    }
  }, [selectAllCategories]);

  return (
    <Grid
      container
      item
      xs={12}
      className={
        !isMobileDevice() &&
        !isTabletDevice() &&
        clsx(classes.bodyBackgroundImg)
      }
    >
      <Container>
        <Grid
          container
          style={{
            // padding: "26px 110px"
            padding: isMobileDevice()
              ? "20px 0px "
              : isTabletDevice()
              ? "64px 80px 20px 80px"
              : "60px 100px 20px 100px",
          }}
        >
          {openGenerateEmbedCodeDialog && (
            <GenerateEmbedCodeDialog
              openGenerateEmbedCodeDialog={openGenerateEmbedCodeDialog}
              setOpenGenerateEmbedCodeDialog={setOpenGenerateEmbedCodeDialog}
              searchedCity={searchedCity}
              searchedCategory={searchedCategory}
              showAllCategories={showAllCategories}
              uuid={uuid}
            />
          )}
          <Grid item xs={12} style={{ marginBottom: 16 }}>
            <Typography
              variant="h1"
              style={{
                textAlign: "center",
                fontWeight: 700,
                fontSize: isMobileDevice() ? "1.5rem" : "2rem",
                lineHeight: 1.6,
                letterSpacing: 0,
              }}
              className={clsx(WebStyles.hankrndFont, WebStyles.samonHeaderTxt)}
            >
              Embed Marketplace
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Paper elevation={0} style={{ padding: 18 }}>
              <Grid container alignItems="center">
                <Grid container item xs={12} alignContent="flex-start">
                  <Grid
                    item
                    className={clsx(
                      WebStyles.barlowFont,
                      WebStyles.paraBlackText
                    )}
                    align="left"
                    style={{
                      fontSize: mdUp ? 28 : 14,
                      marginTop: 15,
                      marginBottom: 20,
                      lineHeight: mdUp ? "38px" : "19px",
                    }}
                  >
                    Please select the categories that you want to show on your
                    website
                  </Grid>

                  <Grid
                    item
                    container
                    xs={12}
                    style={{ alignItems: "center", marginBottom: 20 }}
                  >
                    <Checkbox
                      checked={selectAllCategories || false}
                      onChange={(event) => {
                        setSelectAllCategories(event.target.checked);
                      }}
                      style={{ color: "#0e1c4e" }}
                      // color={WebStyles.navyBlueColorForce}
                    />
                    <Typography
                      variant="body1"
                      className={WebStyles.navyBlueColorForce}
                      style={{ fontWeight: 600 }}
                    >
                      Select All
                    </Typography>
                  </Grid>

                  <ImageList
                    sx={{ width: 500, height: 450 }}
                    cols={7}
                    rowHeight={164}
                  >
                    {displayCategories &&
                      displayCategories.length &&
                      displayCategories.map((category) => (
                        <ImageListItem key={category.name}>
                          {/* <Tooltip key={index} title={category.name} arrow> */}
                          <Grid
                            item
                            // xs={3}
                            // md={1}
                            style={{
                              // minWidth: isMobileDevice()
                              //   ? "28%"
                              //   : isTabletDevice() && "15%",

                              // style={{
                              position: "relative",
                              // width: 100,
                              // height: 100,
                              float: "left",
                              marginLeft: 10,
                              // }}
                            }}
                          >
                            <Grid
                              item
                              style={{
                                width: 75,
                                height: 75,
                                borderRadius: 8,
                                padding: 10,
                                border: "1px solid #0e1c4e",
                                marginRight: isMobileDevice() && 8,
                              }}
                            >
                              <img
                                style={{ width: 50, height: 50 }}
                                src={`/images/Explore-Category/${category.logoUrl}`}
                                alt={category.name}
                                title={category.name}
                              />
                            </Grid>
                            <Grid item>
                              <Typography
                                className={clsx(classes.primarColor)}
                                style={{ fontSize: 14, width: 75, height: 75 }}
                              >
                                <Box m={1}>{category.shortName}</Box>
                              </Typography>
                            </Grid>
                            <input
                              type="checkbox"
                              class="checkbox"
                              id="check2"
                              style={{
                                position: "absolute",
                                // bottom: 0,
                                right: 0,
                                top: 0,
                              }}
                              onClick={(event) => {
                                handleCheckboxChange(
                                  event.target.checked,
                                  category.name
                                );
                              }}
                              checked={category.selected}
                            />
                          </Grid>
                          {/* </Tooltip> */}
                        </ImageListItem>
                      ))}
                  </ImageList>

                  <Grid container item xs={12}>
                    <Grid item xs={11}>
                      <Grid
                        item
                        className={clsx(
                          WebStyles.barlowFont,
                          WebStyles.paraBlackText
                        )}
                        align="left"
                        style={{
                          fontSize: mdUp ? 28 : 14,
                          marginTop: 15,
                          lineHeight: mdUp ? "38px" : "19px",
                        }}
                      >
                        Do you want to specify a default search location on your
                        website? (optional)
                      </Grid>
                    </Grid>
                    <Grid item xs={1} style={{ marginTop: 20 }}>
                      <Switch
                        className={"sqGraySwitch"}
                        checked={showCitiesTextfield}
                        onChange={(e) => {
                          setShowCitiesTextfield(e.target.checked);
                        }}
                      />
                    </Grid>
                  </Grid>
                  {showCitiesTextfield && (
                    <Grid item xs={12}>
                      <Autocomplete
                        options={cities || []}
                        value={searchedCity}
                        // getOptionLabel={(option) => option.shortName || ""}
                        getOptionLabel={(option) => {
                          return `${option.cityDTO[0].shortName} (${option.name})`;
                        }}
                        id="city"
                        debug
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            margin="normal"
                            className={clsx(classes.inputs, classes.textInput)}
                            placeholder="Select default search location"
                          />
                        )}
                        //   filterOptions={(options, { inputValue }) => {
                        //     if (inputValue.length >= 2) {
                        //       return matchSorter(options, inputValue, {
                        //         keys: ["name"],
                        //       });
                        //     } else {
                        //       return [];
                        //     }
                        //   }}
                        onChange={(event, value) => {
                          setSearchedCity(value);
                        }}
                        noOptionsText={"Type in to search"}
                        // getOptionSelected={(option, value) => {
                        //   return option?.shortName === value?.shortName;
                        // }}
                        getOptionSelected={(option, value) => {
                          return (
                            option?.cityDTO[0]?.name === value?.cityDTO[0].name
                          );
                        }}
                        style={{ width: "300px" }}
                      />
                    </Grid>
                  )}
                  <Grid container item xs={12} style={{ marginTop: 32 }}>
                    <Grid item xs={11}>
                      <Grid
                        item
                        className={clsx(
                          WebStyles.barlowFont,
                          WebStyles.paraBlackText
                        )}
                        align="left"
                        style={{
                          fontSize: mdUp ? 28 : 14,
                          marginTop: 15,
                          lineHeight: mdUp ? "38px" : "19px",
                        }}
                      >
                        Do you want to specify a default category on your
                        website? (optional)
                      </Grid>
                    </Grid>
                    <Grid item xs={1} style={{ marginTop: 20 }}>
                      <Switch
                        className={"sqGraySwitch"}
                        checked={showCategoriesTextfield}
                        onChange={(e) => {
                          setShowCategoriesTextfield(e.target.checked);
                        }}
                      />
                    </Grid>
                  </Grid>
                  {showCategoriesTextfield && (
                    <Grid item xs={12} style={{ marginBottom: 55 }}>
                      <Autocomplete
                        options={services || []}
                        value={searchedCategory}
                        getOptionLabel={(option) => option.name || ""}
                        id="category"
                        debug
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            margin="normal"
                            className={clsx(classes.inputs, classes.textInput)}
                            placeholder="Select default search category"
                          />
                        )}
                        //   filterOptions={(options, { inputValue }) => {
                        //     if (inputValue.length >= 2) {
                        //       return matchSorter(options, inputValue, {
                        //         keys: ["name"],
                        //       });
                        //     } else {
                        //       return [];
                        //     }
                        //   }}
                        onChange={(event, value) => {
                          setSearchedCategory(value);
                        }}
                        noOptionsText={"Type in to search"}
                        getOptionSelected={(option, value) => {
                          return option?.name === value?.name;
                        }}
                        style={{ width: "310px" }}
                      />
                    </Grid>
                  )}
                  {/* <Grid container item xs={12} style={{ marginTop: 32 }}>
                    <Grid item xs={11}>
                      <Grid
                        item
                        className={clsx(
                          WebStyles.barlowFont,
                          WebStyles.paraBlackText
                        )}
                        align="left"
                        style={{
                          fontSize: mdUp ? 28 : 14,
                          marginTop: 15,
                          lineHeight: mdUp ? "38px" : "19px",
                        }}
                      >
                        Do you want to show all the categories on your website?
                        (optional)
                      </Grid>
                    </Grid>
                    <Grid item xs={1} style={{ marginTop: 20 }}>
                      <Switch
                        className={"sqGraySwitch"}
                        checked={showAllCategories}
                        onChange={(e) => {
                          setShowAllCategories(e.target.checked);
                        }}
                      />
                    </Grid>
                  </Grid> */}
                  {/* <Grid item xs={12} style={{ marginTop: 44 }}></Grid> */}
                  <Grid
                    item
                    xs={12}
                    style={{ textAlign: "center", marginTop: 48 }}
                  >
                    <Grid item xs={12} style={{ marginTop: 20 }}>
                      <ReCAPTCHA
                        sitekey="6LfjkZYjAAAAAD3fheBcjUDUcCOI7JBqib5ivVx0"
                        ref={captchaRef}
                        onChange={() => setDisableSubmit(false)}
                      />
                    </Grid>
                    <Button
                      style={{
                        backgroundColor: "#F37C61",
                        color: "#FFFFFF",
                        textTransform: "none",
                        borderRadius: mdUp ? 20 : 12,
                        padding: smDown ? "0px 20px" : "8px 36px",
                        fontSize: mdUp ? 20 : 10,
                        height: 50,
                        // marginLeft: smDown ? 0 : 10,
                        // marginTop: smDown ? 20 : 0,
                        marginBottom: smDown ? 20 : 0,
                        opacity: disableSubmit ? 0.5 : 1,
                      }}
                      disabled={disableSubmit}
                      onClick={() => {
                        const newUUID = uuidv4();
                        setUUID(newUUID);
                        let selectedCategories =
                          displayCategories &&
                          displayCategories.length &&
                          displayCategories.filter(
                            (item) => item.selected === true
                          );
                        const token = captchaRef.current.getValue();
                        saveSelectedCategories(
                          newUUID,
                          selectedCategories,
                          token,
                          (res) => {
                            if (res.data.status === 200) {
                              setOpenGenerateEmbedCodeDialog(true);
                              setSelectAllCategories(false);
                            }
                          }
                        );
                        captchaRef.current.reset();
                        // setOpenGenerateEmbedCodeDialog(true);
                      }}
                    >
                      <Grid className={WebStyles.nunitoFont}>
                        Generate Embed Code
                      </Grid>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Container>
      <Divider
        orientation="horizontal"
        // variant="fullWidth"
        style={{ width: "100%", margin: "10px 0" }}
      />
    </Grid>
  );
}
