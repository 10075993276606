import logo from "./logo.svg";
import "./App.css";
import MainLayout from "./components/MainLayout";
import { IntercomProvider } from "react-use-intercom";
import { useEffect } from "react";
const INTERCOM_APP_ID = "v50av2d9";
function App() {
  useEffect(()=>{
    const MainH1 = document.getElementById('mainSeoH1');
    if(MainH1){
      MainH1.remove();
    }
  },[])
  return (
    <div className="App">
      <IntercomProvider appId={INTERCOM_APP_ID}>
        <MainLayout></MainLayout>
      </IntercomProvider>
    </div>
  );
}

export default App;
