import React, { useState } from "react";
import {
  Button,
  Grid,
  Hidden,
  TextField,
  useMediaQuery,
  useTheme,
  Container,
} from "@material-ui/core";
import WebStyles from "../WebStyles.module.css";
import { openPopupWidget } from "react-calendly";
import HomeWebForm from "./home/HomeWebForm";

function SubFooter({ title, subTitle, btnText, who, redirectUrl }) {
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const lgUp = useMediaQuery(theme.breakpoints.up("lg"));

  const [showWaitListForm, setShowWaitListForm] = useState(false);
  const handleClose = () => {
    setShowWaitListForm(false);
  };

  return (
    <Grid
      item
      container
      xs={12}
      style={{ backgroundColor: "#fbe7f0", padding: mdUp ? "0px 10%" : "0px" }}
    >
      <HomeWebForm
        open={showWaitListForm}
        who={who}
        handleClose={handleClose}
      ></HomeWebForm>
      <Container>
        <div style={{ marginBottom: 10 }}>
          <Grid container>
            <Grid
              container
              item
              lg={7}
              md={7}
              xs={12}
              direction="column"
              justifyContent="center"
              style={{ padding: smDown ? 20 : 0 }}
            >
              <Grid item>
                <Grid
                  align="left"
                  style={{
                    fontSize: mdUp ? "40px" : "20px",
                    color: "#D81869",
                    lineHeight: mdUp ? "49px" : "24px",
                    fontWeight: 600,
                  }}
                  className={WebStyles.hankrndFont}
                >
                  {title}
                </Grid>
              </Grid>
              <Grid item style={{ marginTop: 10 }}>
                <Grid
                  align="left"
                  style={{
                    fontSize: mdUp ? "20px" : "10px",
                    color: "#2B3350",
                  }}
                  className={WebStyles.nunitoFont}
                >
                  {subTitle}
                </Grid>
              </Grid>
              <Grid
                container
                item
                style={{ marginTop: mdUp ? "40px" : "20px" }}
              >
                {/* <Grid item lg={8} md={6} xs={12}>
                  <TextField
                    inputProps={{
                      style: {
                        padding: 15,
                      },
                    }}
                    fullWidth
                    placeholder="Enter your email address"
                    style={{
                      // padding: 12,
                      backgroundColor: "#FFFFFF",
                      color: "#2B3350",
                    }}
                  />
                </Grid> */}
                <Grid
                  container
                  item
                  lg={6}
                  md={6}
                  xs={12}
                  // justifyContent="center"
                >
                  {btnText === "Book a Demo" || btnText === "Join Waitlist" ? (
                    <Button
                      style={{
                        backgroundColor: "#D81869",
                        color: "#FFFFFF",
                        textTransform: "none",
                        borderRadius: mdUp ? 20 : 12,
                        padding: smDown ? "0px 20px" : "8px 36px",
                        fontSize: mdUp ? 20 : 10,
                        height: 50,
                        // marginLeft: smDown ? 0 : 10,
                        marginTop: smDown ? 20 : 0,
                        marginBottom: smDown ? 20 : 0,
                      }}
                      onClick={() => {
                        if (btnText === "Book a Demo") {
                          openPopupWidget({
                            url: "https://calendly.com/souqh/30min",
                          });
                        } else if (btnText === "Join Waitlist") {
                          setShowWaitListForm(true);
                        }
                      }}
                    >
                      <Grid className={WebStyles.nunitoFont}>{btnText}</Grid>
                    </Button>
                  ) : (
                    <a
                      href={redirectUrl}
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      <Button
                        style={{
                          backgroundColor: "#D81869",
                          color: "#FFFFFF",
                          textTransform: "none",
                          borderRadius: mdUp ? 20 : 12,
                          padding: smDown ? "0px 20px" : "8px 36px",
                          fontSize: mdUp ? 20 : 10,
                          height: 50,
                          // marginLeft: smDown ? 0 : 10,
                          marginTop: smDown ? 20 : 0,
                          marginBottom: smDown ? 20 : 0,
                        }}
                      >
                        <Grid className={WebStyles.nunitoFont}>{btnText}</Grid>
                      </Button>
                    </a>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Hidden smDown>
              <Grid item xs={5}>
                <img
                  style={{
                    height: lgUp ? 540 : 400,
                    width: lgUp ? 500 : 400,
                    maxWidth: "100%",
                  }}
                  src="/images/footer/Illustration2.svg"
                  alt="Join Free"
                />
              </Grid>
            </Hidden>
          </Grid>
        </div>
      </Container>
    </Grid>
  );
}

export default SubFooter;
