import React from "react";
import { Grid, Link, Typography } from "@material-ui/core";
import WebStyles from "../../WebStyles.module.css";
import clsx from "clsx";

export default function StepBox({ data, mdUp, lgUp, lgDown, index }) {
  return (
    <Grid
      item
      xs={12}
      container
      style={{ paddingRight: 20, alignContent: "flex-start" }}
    >
      <Grid item xs={12} container>
        <Grid
          style={{
            height: "200px",
            width: "90%",
            marginBottom: 12,
            backgroundImage: `url(${data.image}), url(${data.numImg})`,
            backgroundRepeat: "no-repeat, no-repeat",
            backgroundPosition: "center center, 20% 0%",
            backgroundSize: "contain",
          }}
        ></Grid>
      </Grid>
      <Grid
        className={clsx(WebStyles.samonHeaderTxt, WebStyles.hankrndFont)}
        style={{
          fontSize: mdUp ? 40 : 20,
          lineHeight: mdUp ? "48px" : "24px",
          textAlign: "left",
          marginBottom: 12,
          fontWeight: 600,
        }}
      >
        {data.name}
      </Grid>
      <Grid
        className={clsx(WebStyles.paraBlackText, WebStyles.nunitoFont)}
        style={{
          fontSize: mdUp ? 28 : 14,
          lineHeight: mdUp ? "38px" : "19px",
          textAlign: "left",
        }}
      >
        {data.linkText && (<Link
          underline="always"
          className={clsx(WebStyles.paraBlackText, WebStyles.nunitoFont)}
          style={{ cursor: "pointer" }}
          onClick={() => {
            window.open(
              data.link,
              "_blank"
            );
          }}
        >
          {data.linkText}
        </Link>)}
        {data.description}
      </Grid>
    </Grid>
  );
}
