import React, { useEffect } from "react";
import { useStorefrontPage } from "souqh-react-redux-hooks/homeBuyer/useStorefrontPage";
import Helmet from "react-helmet";
import { WEBSITE_DOMAIN_URL } from "./websiteConstants";

export default function MarketPlaceRedirection() {
  const {
    getStorefrontDetailsByName,
    storeFrontDetails,
    storeFrontName,
    businessTypes,
  } = useStorefrontPage();
  let pathParams = window.location.pathname.split("/");
  let name = pathParams[pathParams.length - 1];
  useEffect(() => {
    //get the storefront name which is last parameter in string
    window.location = `https://${WEBSITE_DOMAIN_URL}/storefront/${
      pathParams[pathParams.length - 1]
    }`;
  }, []);

  useEffect(() => {
    if (name) {
      getStorefrontDetailsByName(name);
    }
  }, [name]);

  let businessTypeName =
    businessTypes && businessTypes.length
      ? businessTypes[0].name === "Other"
        ? businessTypes[0].otherBusinessName
        : businessTypes[0].name
      : "";

  return (
    <React.Fragment>
      <Helmet>
        <title>
          {storeFrontName} | {businessTypeName}
        </title>
        <meta name="description" content={storeFrontDetails.companyOverview} />
      </Helmet>
      <div>Loading..</div>
    </React.Fragment>
  );
}
