export const SP_QUESTION_ANSWERS = [
  {
    id: 1,
    question: "When is Souqh launching?",
    answer:
      "We are currently in beta release with some selected realtors, mortgage brokers, lawyers and general contractors. $[Sign_up] for a free account today to join our beta release.",
  },
  {
    id: 2,
    question: "Why should I use Souqh?",
    answer:
      "Souqh is the only end-to-end real estate services marketplace that helps service providers generate more leads and referrals, allows you to reduce your technology spend, save time by bringing your clients on the platform for step-by-step guidance and improve the overall home buying experience for your clients. Free up time to provide the best service to your clients and let Souqh do the rest!",
  },
  {
    id: 3,
    question: "How can Souqh improve my discoverability?",
    answer:
      "We understand how important leads are for you, this is why Souqh uses leading Search Engine Optimization (SEO) techniques such as backlinking, badges inbound marketing, customized QR codes and social media to improve your digital presence. Home buyers are able to narrow down their search results and find service providers based on various filters, allowing them to connect with the right service providers that meet their needs.",
  },
  {
    id: 4,
    question: "Can I feature my storefront on Souqh?",
    answer:
      "Using our marketing campaigns, you can feature your storefront and be one of the first results that clients see when they start their searches. Souqh enables you to launch marketing campaigns that are targeted to clients based on their home buying journey and allows you to feature your storefront directly on their dashboards, enabling you to maximize your Return On Investment (ROI) on marketing.",
  },
  {
    id: 5,
    question: "What insights can Souqh provide me on my clients and prospects?",
    answer:
      "Data driven insights and analytics are at the heart of Souqh. We provide you with detailed storefront analytics, client demographics and marketplace trends to increase the traffic to your storefront and ultimately generate more leads and referrals. Gone are the days of blasting cold emails or mailing brochures with undervalued Return On Investment (ROI).",
  },
  {
    id: 6,
    question: "Who owns my data and is my data secure?",
    answer:
      "You own and govern access to all the data that you upload on Souqh and keeping your data secure is one of our top priorities. We keep your data secure by completely encrypting data at-rest, in-transit, through AWS security protocols and using industry best practices such as 256-SSL encryption. Souqh uses your transaction history to aggregate marketplace trends and uses that data for marketing and predictive analytics.",
  },
  {
    id: 7,
    question: "Can I collaborate with my team members on Souqh?",
    answer:
      "Working with your team on a single platform has never been easier. Souqh allows you to share your documents, clients, leads, and much more. Simply add the information for each team member you would like to invite, select their roles and access levels, and get ready to collaborate!",
  },
  {
    id: 8,
    question: "How can I refer my service provider network to Souqh?",
    answer:
      "Referring other service providers in your network is fast and simple, allowing you to collaborate and send documents with ease with those that you work with often. You can do it directly during onboarding, or any time through your Dashboard. And the best part - you earn referral credits as a bonus for each qualifying service provider you refer to Souqh, and they earn credits for joining as well!",
  },
  {
    id: 9,
    question: "Can my clients pay me directly on Souqh?",
    answer:
      "Absolutely! You can use Souqh to receive instant credit/debit card payments from your clients directly into your bank account. Souqh uses Stripe, which is a leading online payment processing for online businesses. You can also use Souqh to easily inform your active clients details on your accepted method of payments, such as wire transfer details, email money transfer, PayPal or others.",
  },
  {
    id: 10,
    question: "What support options are available?",
    answer:
      "At Souqh, we are passionate about helping you build incredible experiences for your clients. That means we are equally committed to make sure you have the best experience possible as well. There are many support options available to you - check out our Help Centre for how we can help you serve your clients better!",
  },
  {
    id: 11,
    question: "Can I cancel my subscription anytime?",
    answer:
      "While we certainly hope our support team can address any concerns you may have, we understand that sometimes you may need to cancel or deactivate an account. You can cancel your subscription by going to your Billing page under your profile and selecting Deactivate my account. You will continue to have access to Souqh and your storefront until the end of your billing cycle. After that date, your subscription will be deactivated.",
  },
];

export const HB_QUESTION_ANSWERS = [
  {
    id: 1,
    question: "Why should I use Souqh?",
    answer:
      "We know from our own experience how overwhelming and stressful the home journey can be and we were determined to make the process easier. Souqh is Canada’s only end-to-end digital services marketplace who supports home buyers looking for guidance with their home buying journey. Finding a trusted service provider, such as a realtor, mortgage broker, lawyer, or general contractor, has never been easier. From verified reviews, to documentation that is easy to access and locate all in one place, Souqh simplifies your home journey. And the best part? It’s all free!",
  },
  {
    id: 2,
    question: "When is Souqh launching?",
    answer:
      "We are currently in beta release with some selected realtors, mortgage brokers, lawyers and general contractors. $[Sign_up] for a free account today to join our beta release.",
  },
  {
    id: 3,
    question: "Will Souqh guide me through my home journey?",
    answer:
      "Souqh digitally holds your hand through the home buying journey across the many steps to Find your home, Fund your home, Close your home, and Move into your home. It’s like having your very own digital sherpa helping you climb the heights of what can be a complicated process and on to the peak of getting your dream home! Once you are a home owner, Souqh connects you to general contractors to help you maintain and customize your home with lots of bonus credits along the way.",
  },
  {
    id: 4,
    question: "Do I need to pay to use Souqh?",
    answer:
      "Souqh is completely free to use for home buyers and home owners! We charge service providers a small monthly fee to subscribe to the platform and provide you with an improved and incredible experience at no extra cost to you.",
  },
  {
    id: 5,
    question: "Who owns my data and is my data secure?",
    answer:
      "You own and govern access to all the data that you upload on Souqh and keeping your data secure is one of our top priorities. We keep your data secure by completely encrypting data at-rest, in-transit, through AWS security protocols and using industry best practices such as 256-SSL encryption. Souqh uses your transaction history to aggregate marketplace trends and uses that data for marketing and predictive analytics.",
  },
  {
    id: 6,
    question: "How long can my data be stored on Souqh?",
    answer:
      "You can keep your data securely stored on Souqh as long as your account is active - we don’t have any limits on data retention currently. We want to support home buyers throughout their home ownership, so whether you’re buying a home, selling your home or staying put, you can use Souqh to keep all important documents in one place from different providers, which can otherwise be easily misplaced",
  },
  {
    id: 7,
    question: "How can Souqh help me find the right service providers?",
    answer:
      "Finding a trusted service provider, such as a realtor, mortgage broker, lawyer, or general contractor, has never been easier. Our platform allows you to narrow down your search results and find service providers based on various filters, allowing you to connect with the right service providers that meet your needs. Simply visit our Explore page to find the service providers for you.",
  },
  {
    id: 8,
    question: "Who is a “trusted” service provider?",
    answer:
      "We know how important it is to have service providers you can trust throughout the home journey. Souqh reviews every service provider before approving them on our platform to ensure they have strong reviews and a good reputation. Service providers also need to maintain a minimum rating to offer services through our platform.",
  },
  {
    id: 9,
    question: "How can I add my existing service provider on Souqh?",
    answer:
      "When you are looking for your service provider, simply search for their name on our Explore page. If they are not already on Souqh, you will see an option to send an invite to them to join with just a click of a button! Referring qualifying service providers to the platform will provide you credits that you can use towards future transactions.",
  },
  {
    id: 10,
    question: "Can I pay my service providers on Souqh?",
    answer:
      "Absolutely! You can use Souqh to instantly pay service providers using your credit/debit card from the comfort of your home. Souqh uses Stripe, which is a leading online payment processing for online businesses. You can also use Souqh to receive payment instructions from your service provider, such as wire transfer details, email money transfer, PayPal and other accepted methods of payment.",
  },
  {
    id: 11,
    question: "Can I use Souqh if I am already a homeowner?",
    answer:
      "Souqh serves both home buyers and home owners. If you have already found your dream home, Souqh can help connect you with general contractors, like plumbers, electricians, landscapers, and much more. Simply visit our Explore  page to find the service providers that can help you maintain and customize your home! Every time you transact on Souqh, you earn bonus credits that you can use for future transactions!",
  },
];
