import React from "react";
import { Container, Grid, makeStyles, Typography } from "@material-ui/core";
import WebStyles from "../../WebStyles.module.css";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({}));

export default function ServiceItem({
  mdUp,
  lgUp,
  lgDown,
  service,
  isEven,
  commonPadding,
  index,
  smUp,
  xsUp,
}) {
  const classes = useStyles();
  const height = lgUp ? 420 : 360;
  const radius = height / 2;

  return (
    <Grid
      item
      container
      xs={12}
      style={{
        padding: commonPadding,
        position: "relative",
        zIndex: 1,
      }}
    >
      {index === 0 && smUp ? (
        <div>
          <div
            style={{
              position: "absolute",
              backgroundColor: "#72cac9",
              height: height,
              right: -2000,
              left: lgUp ? "10%" : "-15%",
              bottom: 0,
              top: lgUp ? "-90%" : "-50%",
              zIndex: -1,
              borderTopLeftRadius: lgUp ? radius : "0px",
              borderBottomLeftRadius: lgUp ? radius : "0px",
              transform: "rotate(-10deg)",
              transformOrigin: "center",
              opacity: 0.25,
            }}
          />
          <div
            style={
              lgUp
                ? {
                    zIndex: 1,
                    backgroundImage:
                      "url('/images/service_providers/orange patch.svg')",
                    backgroundRepeat: "no-repeat",
                    height: "500px",
                    width: "400px",
                    position: "absolute",
                    right: "0px",
                    bottom: "-35%",
                    backgroundSize: "50% 100%",
                    backgroundPosition: "right",
                  }
                : {}
            }
          />
        </div>
      ) : null}

      {index === 2 && smUp ? (
        <div>
          <div
            style={{
              position: "absolute",
              backgroundColor: "#F37C61",
              height: height,
              left: -2000,
              right: lgUp ? "10%" : "-15%",
              bottom: 0,
              top: lgUp ? "-18%" : "-5%",
              zIndex: -1,
              borderTopRightRadius: lgUp ? radius : "0px",
              borderBottomRightRadius: lgUp ? radius : "0px",
              transform: "rotate(10deg)",
              transformOrigin: "center",
              opacity: 0.2,
            }}
          ></div>
          <div
            style={
              lgUp
                ? {
                    zIndex: 1,
                    backgroundImage:
                      index === 2
                        ? "url('/images/service_providers/Orange_patch_left.svg')"
                        : "",
                    backgroundRepeat: "no-repeat",
                    height: "600px",
                    width: "400px",
                    position: "absolute",
                    left: "0px",
                    bottom: "-30%",
                    backgroundSize: "60% 100%",
                  }
                : {}
            }
          />
        </div>
      ) : null}

      {index === 4 && smUp ? (
        <div
          style={{
            position: "absolute",
            backgroundColor: "#B872AF",
            height: height,
            right: -2000,
            left: lgUp ? "10%" : "-15%",
            bottom: 0,
            top: lgUp ? "-50%" : "-50%",
            zIndex: -1,
            borderTopLeftRadius: lgUp ? radius : "0px",
            borderBottomLeftRadius: lgUp ? radius : "0px",
            transform: "rotate(-10deg)",
            transformOrigin: "center",
            opacity: 0.1,
          }}
        ></div>
      ) : null}
      <Container>
        <Grid
          item
          container
          xs={12}
          style={{
            flexDirection: isEven ? "row" : "row-reverse",
            justifyContent: "flex-end",
          }}
        >
          <Grid item xs={1}></Grid>
          <Grid
            item
            container
            alignContent="center"
            xs={6}
            md={5}
            style={{
              alignContent: "center",
              paddingLeft: isEven ? 0 : 32,
              paddingRight: isEven ? 32 : 0,
            }}
          >
            <Grid
              className={clsx(WebStyles.hankrndFont)}
              style={{
                fontSize: mdUp ? 40 : 18,
                lineHeight: mdUp ? "48px" : "24px",
                textAlign: "left",
                // marginBottom: mdUp ? 12 : 8,
                color: service.color,
                fontWeight: 600,
              }}
            >
              {service.name}
            </Grid>
            <Grid
              className={clsx(WebStyles.hankrndFont)}
              style={{
                fontSize: mdUp ? 40 : 18,
                lineHeight: mdUp ? "48px" : "24px",
                textAlign: "left",
                marginBottom: mdUp ? 12 : 8,
                color: service.color,
                fontWeight: 600,
              }}
            >
              {service.subName}
            </Grid>
            <Grid
              className={clsx(WebStyles.nunitoFont, WebStyles.paraBlackText)}
              style={{
                fontSize: mdUp ? 28 : 14,
                lineHeight: mdUp ? "38px" : "19px",
                textAlign: "left",
                marginBottom: mdUp ? 12 : 8,
              }}
            >
              {service.description}
            </Grid>
          </Grid>
          <Grid item xs={5} md={6} style={{ display: "flex", alignItems: "center" }}>
            <img
              style={{
                // width: mdUp ? service.width : "auto",
                height: mdUp ? service.height : "auto",
                maxWidth: "100%",
                // transform: service.transform,
              }}
              src={service.image}
              alt={service.name}
              title={service.name}
            ></img>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
}
