import { Grid } from "@material-ui/core";
import React from "react";

export default function PriceAmountBox({ mdUp, mdDown, data, planType }) {
  return (
    <>
      <Grid
        item
        container
        xs={12}
        style={{
          alignItems: "center",
          position: "relative",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            fontSize: mdUp ? 20 : 10,
            fontWeight: "600",
            alignItems: "flex-start",
            justifyContent: "flex-end",
            display: "flex",
            paddingBottom: 60,
            height: "100%",
          }}
        >
          $
        </div>
        <div
          style={{
            fontSize: mdUp ? 120 : 60,
            lineHeight: mdUp ? "144px" : "72px",
            marginBottom: mdUp ? 12 : 8,
            fontWeight: "600",
            display: "flex",
          }}
        >
          {planType ? data.amountAnnually : data.amountMonthly}
        </div>
        <div
          style={{
            fontWeight: "500",
            height: "100%",
          }}
        >
          {/* <div
          style={{
            fontSize: mdUp ? 36 : 18,
            lineHeight: mdUp ? "43px" : "21px",
            marginBottom: mdUp ? 12 : 8,
            fontWeight: "500",
            alignItems: "flex-end",
            display: "flex",
            height: "50%",
            fontWeight: "600",
          }}
        >
          {planType ? (
            <span>{data.fraction}</span>
          ) : (
            <span style={{ visibility: "hidden" }}>.</span>
          )}
        </div> */}
          {/* <div
          style={{
            fontSize: mdUp ? 22 : 11,
            lineHeight: mdUp ? "26px" : "13px",
            marginBottom: mdUp ? 12 : 8,
            fontWeight: "500",
            alignItems: "flex-end",
            display: "flex",
            paddingBottom: 16,
            height: "50%",
          }}
        >
          {planType ? "/year/user" : "/mo/user"}
        </div> */}
        </div>
      </Grid>
      <div
        style={{
          fontSize: mdUp ? 22 : 11,
          lineHeight: mdUp ? "26px" : "13px",
          marginBottom: mdUp ? 12 : 8,
          fontWeight: "500",
          alignItems: "flex-end",
          display: "flex",
          paddingBottom: 16,
          // height: "50%",
          width: "100%",
          justifyContent: "center",
        }}
      >
        {planType ? "/year/user" : "/mo/user"}
      </div>
    </>
  );
}
