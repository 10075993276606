import React, { useEffect } from "react";
import HowItWorks from "./HowItWorks";
import { Grid, useMediaQuery, useTheme } from "@material-ui/core";
import Features from "./Features";
import Introduction from "./Introduction";
import SubFooter from "../SubFooter";
import CompanyLogos from "../serviceProviders/CompanyLogos";
import { WEBSITE_DOMAIN_URL } from "../websiteConstants";

function HomeBuyersPage() {
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  const lgUp = useMediaQuery(theme.breakpoints.up("lg"));
  const lgDown = useMediaQuery(theme.breakpoints.down("lg"));
  const xlUp = useMediaQuery(theme.breakpoints.up("xl"));
  const xsDown = useMediaQuery(theme.breakpoints.down("xs"));
  const commonPadding = lgUp ? "0px 6%" : "0px 2%";
  // const commonPadding = 0;

  const title = "Why Wait?";
  const subTitle =
    "We designed Souqh to help eliminate the stress in the home journey. And best of all, Souqh is free to use!";
  const btnText = "Sign up now for free!";
  const redirectUrl = `https://${WEBSITE_DOMAIN_URL}/homebuyersignup`;

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div
      style={{
        overflow: "hidden",
        position: "relative",
        top: smUp ? (lgUp ? 55 : 60) : 78,
        marginBottom: 105,
      }}
    >
      <Introduction
        smUp={smUp}
        smDown={smDown}
        mdUp={mdUp}
        mdDown={mdDown}
        lgUp={lgUp}
        xlUp={xlUp}
        xsDown={xsDown}
        commonPadding={commonPadding}
      />
      <Features
        smUp={smUp}
        smDown={smDown}
        mdUp={mdUp}
        lgUp={lgUp}
        lgDown={lgDown}
        xlUp={xlUp}
        commonPadding={commonPadding}
      />
      <HowItWorks
        smUp={smUp}
        mdUp={mdUp}
        lgUp={lgUp}
        lgDown={lgDown}
        xlUp={xlUp}
        commonPadding={commonPadding}
      />
      <CompanyLogos
        title={"You are in good company"}
        smUp={smUp}
        mdUp={mdUp}
        mdDown={mdDown}
        lgUp={lgUp}
        lgDown={lgDown}
        xsDown={xsDown}
        commonPadding={commonPadding}
        subHeader={"Connect with 3,000+ Real Estate Services and Home Improvement Professionals"}
      />
      <SubFooter
        title={title}
        subTitle={subTitle}
        btnText={btnText}
        who="homeBuyer"
        redirectUrl={redirectUrl}
      />
    </div>
  );
}

export default HomeBuyersPage;
