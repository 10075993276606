import React, { useEffect } from "react";
import { useMediaQuery, useTheme } from "@material-ui/core";
import MoreGreatFeatures from "./MoreGreatFeatures";
import Services from "./Services";
import HowItWorks from "./HowItWorks";
import BusinessTypes from "./BusinessTypes";
import Pricing from "./Pricing";
import SubFooter from "../SubFooter";
import CompanyLogos from "./CompanyLogos";
import ExperiencePremium from "./ExperiencePremium";
import ReviewCarousel from "../home/ReviewCarousel";

function ServiceProvidersPage() {
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  const lgUp = useMediaQuery(theme.breakpoints.up("lg"));
  const lgDown = useMediaQuery(theme.breakpoints.down("lg"));
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const xsUp = useMediaQuery(theme.breakpoints.up("xs"));
  const xsDown = useMediaQuery(theme.breakpoints.down("xs"));
  const xlUp = useMediaQuery(theme.breakpoints.up("xl"));
  const commonPadding = lgUp ? "0px 10%" : "0px 2%";

  const title = "Still wondering if we're right for you?";
  // const subTitle =
  //   "We offer a free 30-day, no strings attached trial of all our premium features. Speak to one of our sales reps today and join our service provider network.";
  const subTitle = "Book a demo with one of our customer support specialists to see Souqh in action and experience a simplified homeownership experience for you and your clients. "
  const btnText = "Book a Demo";

  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div
      style={{
        marginBottom: 85,
        overflow: "hidden",
        position: "relative",
        top: smUp ? (lgUp ? 35 : 16) : 48,
      }}
    >
      <MoreGreatFeatures
        smUp={smUp}
        mdUp={mdUp}
        lgUp={lgUp}
        lgDown={lgDown}
        xsDown={xsDown}
        commonPadding={commonPadding}
      />
      <BusinessTypes
        smUp={smUp}
        mdUp={mdUp}
        lgUp={lgUp}
        commonPadding={commonPadding}
      />
      <Services
        smUp={smUp}
        mdUp={mdUp}
        lgUp={lgUp}
        lgDown={lgDown}
        xsUp={xsUp}
        commonPadding={commonPadding}
      />
      <Pricing
        smUp={smUp}
        mdUp={mdUp}
        lgUp={lgUp}
        mdDown={mdDown}
        smDown={smDown}
        commonPadding={commonPadding}
      />
      {smUp ? (
        <ExperiencePremium commonPadding={commonPadding} mdUp={mdUp} />
      ) : null}

      <HowItWorks
        smUp={smUp}
        mdUp={mdUp}
        lgUp={lgUp}
        lgDown={lgDown}
        commonPadding={commonPadding}
      />
      <ReviewCarousel
        smDown={smDown}
        mdUp={mdUp}
        mdDown={mdDown}
        lgUp={lgUp}
        lgDown={lgDown}
        xlUp={xlUp}
        commonPadding={commonPadding} />
      <CompanyLogos
        title={"You are in good company"}
        subHeader={"Join 3,000+ real estate services and home improvement professionals who are using Souqh to simplify the homeownership experience for their clients"}
        smUp={smUp}
        mdUp={mdUp}
        mdDown={mdDown}
        lgUp={lgUp}
        lgDown={lgDown}
        xsDown={xsDown}
        commonPadding
      />
      <SubFooter
        title={title}
        subTitle={subTitle}
        btnText={btnText}
        who="serviceProvider"
      />
    </div>
  );
}

export default ServiceProvidersPage;
