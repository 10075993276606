import {
  Button,
  Container,
  Grid,
  TextField,
  Hidden,
  Typography,
} from "@material-ui/core";
import React, { useState, useRef } from "react";
import WebStyles from "../../WebStyles.module.css";
import clsx from "clsx";
import { postData } from "../home/HomeWebForm";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import ReCAPTCHA from "react-google-recaptcha";
import FrequentlyAskedQuestions from "../home/FrequentlyAskedQuestions";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Section1({ smDown, mdUp, lgUp, commonPadding }) {
  const [inProgress, setinProgress] = useState(false);
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [message, setMessage] = useState("");

  const [open, setOpen] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(true);

  const captchaRef = useRef(null);

  return (
    <Grid
      item
      container
      xs={12}
      style={{
        backgroundImage: lgUp
          ? "url('/images/home_buyers/Green patch.svg')"
          : "none",
        backgroundRepeat: "no-repeat",
        backgroundSize: "10%",
        padding: commonPadding,
        marginBottom: 50,
      }}
    >
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => {
          setOpen(false);
        }}
      >
        <Alert
          style={{ backgroundColor: "#1b1e4c" }}
          onClose={() => {
            setOpen(false);
          }}
          severity="success"
        >
          Message submitted successfuly
        </Alert>
      </Snackbar>
      <Container>
        <Container>
          <Grid container>
            <Grid container item xs={12}>
              <Hidden mdDown>
                <Grid container item xs={12} md={6} lg={1}></Grid>
              </Hidden>
              <Grid
                container
                item
                xs={12}
                md={6}
                lg={4}
                alignContent="center"
                style={{ paddingLeft: lgUp && 64 }}
              >
                <Typography
                  variant="h1"
                  item
                  xs={12}
                  className={clsx(
                    WebStyles.hankrndFont,
                    WebStyles.samonHeaderTxt
                  )}
                  style={{
                    textAlign: "left",
                    fontSize: lgUp ? 80 : mdUp ? 60 : 40,
                    lineHeight: lgUp ? "74px" : mdUp ? "64px" : "37px",
                    fontWeight: 600,
                    marginBottom: mdUp ? 24 : 12,
                  }}
                >
                  Get in touch
                </Typography>
                <Grid
                  item
                  xs={12}
                  className={clsx(WebStyles.captionBlack, WebStyles.nunitoFont)}
                  style={{
                    textAlign: "left",
                    fontSize: mdUp ? 28 : 14,
                    marginLeft: mdUp && 8,
                  }}
                >
                  Contact us today to learn how Souqh can help you.
                </Grid>
              </Grid>
              <Hidden mdDown>
                <Grid container item xs={12} md={6} lg={3}></Grid>
              </Hidden>
              <Hidden smDown>
                <Grid container item xs={12} md={6} lg={4}>
                  <img
                    src="/images/contact/Illustration1.svg"
                    alt="Illustration svg"
                    style={{
                      height: lgUp ? 300 : 225,
                      width: lgUp ? 405 : 304,
                    }}
                  />
                </Grid>
              </Hidden>
            </Grid>

            <Grid container item xs={12} style={{ marginTop: smDown ? 20 : 0 }}>
              <Hidden mdDown>
                <Grid container item xs={12} md={6} lg={1}></Grid>
              </Hidden>
              <Grid
                container
                item
                xs={12}
                md={6}
                lg={4}
                alignContent="center"
                style={{
                  paddingBottom: 30,
                  lineHeight: "40px",
                  paddingLeft: lgUp && 64,
                }}
              >
                <Grid
                  container
                  item
                  xs={12}
                  className={clsx(
                    WebStyles.samonHeaderTxt,
                    WebStyles.hankrndFont
                  )}
                  style={{
                    fontSize: 20,
                    fontWeight: 600,
                    marginLeft: mdUp && 8,
                  }}
                >
                  Support
                </Grid>
                {/* <Grid
                  container
                  item
                  xs={12}
                  style={{ paddingLeft: mdUp ? 8 : 0 }}
                >
                  <Grid
                    container
                    item
                    xs={1}
                    justifyContent="flex-start"
                    style={{ marginTop: 5 }}
                  >
                    <img
                      src="/images/contact/Phone Copy.svg"
                      alt="Phone Call Icon"
                    />
                  </Grid>
                  <Grid
                    container
                    item
                    xs={11}
                    justifyContent="flex-start"
                    style={{ textAlign: "left", fontSize: 15 }}
                    className={clsx(
                      WebStyles.captionBlack,
                      WebStyles.nunitoFont
                    )}
                  >
                    <a
                      style={{ color: "#2B3350", textDecoration: "none" }}
                      href="tel:+1 (647) 871 3786"
                    >
                      +1 (647) 871 3786
                    </a>
                  </Grid>
                </Grid> */}
                <Grid
                  container
                  item
                  xs={12}
                  style={{ paddingLeft: mdUp ? 8 : 0 }}
                >
                  <Grid
                    container
                    item
                    xs={1}
                    justifyContent="flex-start"
                    style={{ marginTop: 5 }}
                  >
                    <img src="/images/contact/Email.svg" alt="Email Icon" />
                  </Grid>
                  <Grid
                    container
                    item
                    xs={11}
                    justifyContent="flex-start"
                    style={{ textAlign: "left", fontSize: 15 }}
                    className={clsx(
                      WebStyles.captionBlack,
                      WebStyles.nunitoFont
                    )}
                  >
                    <a
                      style={{ color: "#2B3350", textDecoration: "none" }}
                      href="mailto:support@souqh.ca"
                    >
                      support@souqh.ca
                    </a>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={7}>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    setinProgress(true);
                    console.log(captchaRef);
                    const token = captchaRef.current.getValue();
                    const payload = {
                      email: email,
                      fullName: fullName,
                      message: message,
                      token: token,
                    };
                    postData(
                      process.env.REACT_APP_SOUQH_API_SERVER_BASE_URL +
                        "/souqh-waitlist/contact-us",
                      payload
                    )
                      .then((data) => {
                        setinProgress(false);
                        setFullName("");
                        setEmail("");
                        setMessage("");
                        setOpen(true);
                      })
                      .catch((e) => {
                        setinProgress(false);
                      });
                    captchaRef.current.reset();
                  }}
                >
                  <Grid
                    container
                    // justifyContent="space-between"
                    item
                    xs={12}
                    lg={12}
                    style={{ marginTop: smDown ? 0 : 16 }}
                  >
                    <Grid item xs={12} lg={6}>
                      <TextField
                        fullWidth
                        label="Your name"
                        style={{ paddingRight: 8, fontSize: mdUp ? 20 : 10 }}
                        value={fullName}
                        onChange={(e) => {
                          setFullName(e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      lg={6}
                      style={{
                        paddingLeft: lgUp ? 16 : 0,
                      }}
                    >
                      <TextField
                        fullWidth
                        label="Your email"
                        style={{
                          paddingLeft: lgUp ? 0 : 0,
                          fontSize: mdUp ? 20 : 10,
                        }}
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        inputProps={{
                          type: "email",
                          pattern:
                            "[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}$",
                          title:
                            "Plase provide valid email address. e.g. foo@example.com",
                          style: {
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0,
                            borderLeft: "none",
                          },
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} style={{ marginTop: 20 }}>
                      <TextField
                        multiline
                        rowsMax={4}
                        fullWidth
                        label="Message"
                        style={{ fontSize: mdUp ? 20 : 10 }}
                        value={message}
                        onChange={(e) => {
                          setMessage(e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: 20 }}>
                      <ReCAPTCHA
                        sitekey="6LfjkZYjAAAAAD3fheBcjUDUcCOI7JBqib5ivVx0"
                        ref={captchaRef}
                        onChange={() => setDisableSubmit(false)}
                      />
                    </Grid>
                    <Grid container item xs={12} justifyContent="flex-end">
                      <Button
                        style={{
                          backgroundColor: "#F37C61",
                          color: "#FFFFFF",
                          textTransform: "none",
                          borderRadius: mdUp ? 24 : 12,
                          marginTop: 20,
                          marginLeft: lgUp ? 20 : 0,
                          padding: "8px 20px",
                          opacity:
                            !fullName ||
                            !email ||
                            !message ||
                            inProgress ||
                            disableSubmit
                              ? 0.5
                              : 1,
                        }}
                        disabled={
                          !fullName ||
                          !email ||
                          !message ||
                          inProgress ||
                          disableSubmit
                        }
                        type="submit"
                      >
                        <Grid
                          className={WebStyles.hankrndFont}
                          style={{ fontSize: mdUp ? 20 : 10 }}
                        >
                          Send Message
                        </Grid>
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </Grid>
  );
}

export default Section1;
