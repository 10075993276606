import { Grid } from "@material-ui/core";
import React from "react";

export default function PlanRibbon({ smDown, data }) {
  return (
    <Grid
      item
      xs={12}
      style={{
        display: "flex",
        position: "relative",
        justifyContent: "flex-end",
        right: -16,
      }}
    >
      <div className={"customRibbon"}></div>
      <Grid
        style={{
          fontSize: 20,
          lineHeight: "24px",
          height: 36,
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          paddingRight: 24,
          fontWeight: "bold",
          backgroundColor: "#FA7E61",
          marginLeft: -1,
        }}
      >
        {data.name}
      </Grid>
    </Grid>
  );
}
