import React from "react";
import { Grid, Container } from "@material-ui/core";
import WebStyles from "../../WebStyles.module.css";
import FeatureItem from "./FeatureItem";
import clsx from "clsx";

function Features({ smUp, smDown, mdUp, lgUp, lgDown, xlUp, commonPadding }) {
  const features = [
    {
      // name: "Ditch the stress",
      name: "Home Ownership – Simplified",
      color: "#72cac9",
      // image: "/images/home_buyers/Illustration 14.svg",
      image: "/images/home_buyers/One stop connection.svg",
      // description:
      //   "Buying a home can be a stressful life decision - we designed Souqh to help eliminate some of that stress. We are the only end-to-end digital services platform that provides digital guidance and access to a trusted service provider network, all in one place. Buying a home has never been easier with Souqh",
      description: "Experience a simplified, digital homeownership experience. From connecting and collaborating with your realtor, to securing home financing, booking home inspections, performing virtual closings, scheduling your move, transferring utilities, updating addresses, and getting any type of home improvement project completed - our homeownership hub includes all the self-serve tools you need to manage and track your entire homeownership journey – in one place."
    },
    {
      // name: "Trusted Service Providers",
      name: "Services Marketplace – Redefined",
      color: "#72cac9",
      image: "/images/home_buyers/Trusted Service Providers.svg",
      // description:
      //   "Worried about the right fit? Souqh helps you find trusted service providers across a pool of storefronts that match your unique needs, along with verified ratings and testimonials from home buyers just like you.",
      description: "Tired of calling, texting and overflown inboxes with missed attachments? Simply connect, collaborate and transact with trusted Realtors, Mortgage Brokers, Home Inspectors, Real Estate Lawyers, Movers, Cleaners, Handymen and all types of home improvement professionals using your homeownership hub.",
    },
    {
      // name: "Streamlined Documents",
      name: "Move Management – Streamlined",
      color: "#F37C61",
      // image: "/images/home_buyers/Illustartion 15.svg",
      image: "/images/home_buyers/Illustration 16.svg",
      // description:
      //   "We know it can be frustrating to manage all the documents required throughout your home buying journey. Souqh streamlines all your documents in a simple and secure portal, allowing you to create, share, e-sign and upload documents directly to service providers.",
      description: "Comprehensive self-serve support with all moving related tasks such as hiring a mover, scheduling services, transferring utilities, updating addresses, paying movers and live support from our team.",
    },
    {
      // name: "Communicate Freely",
      name: "Documents – Consolidated",
      color: "#F37C61",
      // image: "/images/home_buyers/Illustration 16.svg",
      image: "/images/home_buyers/Illustartion 15.svg",
      // description:
      //   "Tired of calling, texting and overflown inboxes with missed attachments? Souqh simplifies all communications with service providers, right on the platform, with easy access and interaction history.",
      description: "Using your homeownership hub, you can securely organize, store and share all your homeownership documents, including your purchase and sale agreement, home inspection report, home financing documents, closing documents, moving contract, invoices, warranties and any other homeownership related documents."
    },
    {
      name: "Home Improvements – Made Easy",
      color: "#B872AF",
      image: "/images/home_buyers/Illustration 14.svg",
      description: "Simply post your services request on Souqh, compare quotes from multiple home improvement professionals, schedule services, coordinate with vendors and securely pay using your homeownership hub.",
    },
    // {
    //   name: "Earn Credits",
    //   color: "#B872AF",
    //   image: "/images/home_buyers/Illustartion 17.svg",
    //   description:
    //     "Every time you interact on Souqh or refer a service provider, you earn credits that can be used for future services. It pays to transact on Souqh!",
    // },
    {
      // name: "Rest Easy",
      name: "Security – Under Control",
      color: "#B872AF",
      image: "/images/home_buyers/Illustration 18.svg",
      // description:
      //   "Rest easy knowing that you completely own and control your data. Our enterprise-grade cloud security, which runs on industry-leading Amazon cloud, protects your data using 256-bit SSL/TLS encryption.",
      description: "Rest easy knowing that you completely own and control your data. Our enterprise-grade cloud security, which runs on industry-leading Amazon cloud (Canada Region), protects your data using 256-bit SSL/TLS encryption."
    },
  ];
  return (
    <Grid
      item
      container
      xs={12}
      style={{
        backgroundImage: lgUp
          ? "url('/images/home_buyers/Purple patch.svg'), url('/images/home_buyers/orange patch_right_2.svg')"
          : "none",
        backgroundRepeat: "no-repeat, no-repeat, no-repeat",
        backgroundSize: "10%, 7%",
        backgroundPosition: "0% 50%, 100% 35%",
        marginTop: lgUp && 48,
        padding: commonPadding,
      }}
    >
      <Container>
        <Container>
          <Grid item xs={12} lg={12} container style={{ paddingBottom: 40 }}>
            <Grid
              item
              xs={12}
              style={{
                marginBottom: 48,
              }}
            >
              <Grid
                align="center"
                className={clsx(WebStyles.blueHeaderTxt, WebStyles.hankrndFont)}
                style={{
                  fontSize: mdUp ? 40 : 20,
                  lineHeight: mdUp ? "48px" : "24px",
                  marginBottom: mdUp ? 12 : 8,
                }}
              >
                {/* So many great features to help your journey */}
                One Homeownership Hub
              </Grid>
              <Grid
                align="center"
                className={clsx(WebStyles.paraBlackText, WebStyles.nunitoFont)}
                style={{
                  fontSize: mdUp ? 28 : 14,
                  lineHeight: mdUp ? "38px" : "19px",
                  marginBottom: mdUp ? 12 : 8,
                }}
              >
                Managing your home ownership journey has never been easier using Souqh’s Homeownership Hub.
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {features.map((item, index) => {
                return (
                  <Grid
                    key={index}
                    style={{ position: "relative", paddingBottom: 32 }}
                  >
                  <FeatureItem
                    key={index}
                    smUp={smUp}
                    mdUp={mdUp}
                    feature={item}
                    isEven={index % 2 === 0}
                    lgUp={lgUp}
                    lgDown={lgDown}
                    index={index}
                    smDown={smDown}
                  />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Container>
      </Container>
    </Grid>
  );
}

export default Features;
