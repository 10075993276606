import { Button, Chip, Container, Grid, Snackbar } from "@material-ui/core";
import React from "react";
import clsx from "clsx";
import WebStyles from "../../WebStyles.module.css";
import MuiAlert from "@material-ui/lab/Alert";
import { useExplore } from "souqh-react-redux-hooks/homeBuyer/useExplore";
import { WEBSITE_DOMAIN_URL } from "../websiteConstants";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function ExploreServiceProvider({
  selectedTab,
  smDown,
  mdUp,
  lgUp,
  lgDown,
  mdDown,
  smUp,
  commonPadding,
}) {
  const [showComingSoon, setShowComingSoon] = React.useState(false);
  // let businessTypes = [
  //   "Realtor",
  //   "Mortgage Broker",
  //   "Lawyer",
  //   "Contractor",
  //   "Mover",
  // ];
  const { formSaveCategoryObjAndCallApi } = useExplore();

  let businessTypes = [
    {
      id: 1,
      name: "Real Estate Broker / Agent",
      shortName: "Realtor",
      logoUrl: "Realtor.svg",
      isBusinessType: true,
      sequenceNumber: 1,
      storefrontPathName: "real-estate-agent",
    },
    {
      id: 2,
      name: "Mortgage Broker / Agent",
      shortName: "Mortgage Broker",
      logoUrl: "MortgageBroker.svg",
      isBusinessType: true,
      sequenceNumber: 2,
      storefrontPathName: "mortgage-broker-agent",
    },
    {
      id: 3,
      name: "Real Estate Lawyer",
      shortName: "Lawyer",
      logoUrl: "LegalServices.svg",
      isBusinessType: true,
      sequenceNumber: 3,
      storefrontPathName: "real-estate-lawyer",
    },
    {
      id: 6,
      name: "Mover",
      shortName: "Moving",
      logoUrl: "Movers.svg",
      isBusinessType: true,
      sequenceNumber: 4,
      storefrontPathName: "mover",
    },
    {
      shortName: "And More",
    },
  ];

  return (
    <Grid
      item
      container
      lg={11}
      md={12}
      style={{
        padding: commonPadding,
        backgroundColor: "#d9f5f3",
        marginTop: 20,
        borderTopRightRadius: lgUp ? 210 : 0,
        borderBottomRightRadius: lgUp ? 210 : 0,
      }}
    >
      <Snackbar
        open={showComingSoon}
        autoHideDuration={6000}
        onClose={() => {
          setShowComingSoon(false);
        }}
        ContentProps={{ style: { backgroundColor: "#1b1e4c", minWidth: 0 } }}
        // style={{ display: "flex" }}
        message={
          <Grid container justifyContent="center" item xs={12}>
            Coming soon !
          </Grid>
        }
      >
        {/* <Grid
          container
          justifyContent="center"
          item
          xs={12}
          style={{ backgroundColor: "#1b1e4c" }}
        >
          Coming soon !
        </Grid> */}
      </Snackbar>
      <Container
        style={{ paddingBottom: 20, paddingTop: 10, padding: "46px 20px 50px" }}
      >
        <Grid
          align="left"
          xs={12}
          item
          className={clsx(WebStyles.hankrndFont, WebStyles.blueHeaderTxt)}
          style={{
            fontSize: mdUp ? 40 : 20,
            lineHeight: mdUp ? "48px" : "24px",
            fontWeight: 600,
          }}
        >
          {/* {selectedTab === 0
            ? "Explore Service Providers"
            : "Become a Service Provider"} */}
          {selectedTab === 0
            ? "One Homeownership Hub"
            : "Strengthen Homeowner Relationships"}
        </Grid>
        <Grid
          align="left"
          xs={12}
          item
          className={clsx(WebStyles.barlowFont, WebStyles.paraBlackText)}
          style={{
            fontSize: mdUp ? 28 : 14,
            lineHeight: mdUp ? "38px" : "19px",
            marginTop: 10,
          }}
        >
          <Grid lg={12} md={12} sm={12} item>
            {" "}
            {/* {selectedTab === 0
              ? " Simply search, compare, and connect with trusted service providers - all in one platform."
              : "Your one-stop connection to leads, referrals, technology and digital marketing."} */}
            {selectedTab === 0
              ? "Experience a simplified, digital homeownership experience. From connecting and collaborating with your realtor, to securing home financing, booking home inspections, performing virtual closings, scheduling your move, transferring utilities, updating addresses, and getting any type of home improvement project completed - our homeownership hub includes all the self-serve tools you need to manage and track your entire homeownership journey – in one place. "
              : "Build long-term client relationships that increase repeat & referral business, while reducing marketing expenses, and expanding revenue opportunities."}
          </Grid>
        </Grid>
        <Grid container item align="left" style={{ marginTop: 10 }}>
          {/* <Grid item lg={8} md={7} xs={9} style={{ marginTop: 10 }}>
            {businessTypes.map((type, index) => (
              <Chip
                key={index}
                style={{
                  marginRight: 5,
                  color: "#72CAC9",
                  fontFamily: "sq-hank-rnd",
                  fontWeight: 500,
                  fontSize: mdUp ? 20 : 10,
                  backgroundColor: "#FFFF",
                  marginBottom: lgDown ? 10 : 0,
                }}
                label={type.shortName}
                onClick={() => {
                  formSaveCategoryObjAndCallApi(type);
                  const explorePageUrl = type.storefrontPathName
                    ? `https://${WEBSITE_DOMAIN_URL}/marketplace/${type.storefrontPathName}`
                    : `https://${WEBSITE_DOMAIN_URL}/marketplace`;
                  window.open(explorePageUrl);
                }}
              />
            ))}
          </Grid> */}
          <Grid
            item
            lg={11}
            md={12}
            container
            justifyContent="flex-end"
            style={{ marginTop: mdDown ? 10 : 0 }}
          >
            <Button
              style={{
                backgroundColor: "#72CAC9",
                color: "#FFFFFF",
                textTransform: "none",
                borderRadius: 20,
                marginLeft: smDown ? 20 : 0,
                padding: "8px 20px",
                fontSize: mdUp ? 20 : 10,
              }}
              onClick={() => {
                if (selectedTab === 0) {
                  // window.open(`https://${WEBSITE_DOMAIN_URL}/marketplace`);
                  window.open(`https://${WEBSITE_DOMAIN_URL}/homebuyersignup`);
                } else {
                  window.open(`https://${WEBSITE_DOMAIN_URL}/new`);
                }
              }}
            >
              <Grid item className={WebStyles.nunitoFont}>
                {/* {selectedTab === 0
                  ? "Explore Service Providers"
                  : "Become a Service Provider"} */}
                Join Souqh Today
              </Grid>
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
}

export default ExploreServiceProvider;
