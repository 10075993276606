import { Grid, Typography, Container } from "@material-ui/core";
import React from "react";
import WebStyles from "../../WebStyles.module.css";
import clsx from "clsx";

export default function ServicesTitle({ mdUp, commonPadding }) {
  return (
    <Grid
      item
      container
      xs={12}
      style={{
        padding: commonPadding,
      }}
    >
      <Container>
        <Grid
          item
          xs={12}
          style={{
            paddingBottom: mdUp ? 60 : 36,
          }}
        >
          <Grid
            className={clsx(WebStyles.blueHeaderTxt, WebStyles.hankrndFont)}
            style={{
              fontSize: mdUp ? 40 : 20,
              lineHeight: mdUp ? "48px" : "24px",
              marginBottom: mdUp ? 12 : 8,
              textAlign: "center",
              fontWeight: 600,
            }}
          >
            {/* Stand out and deliver the best */}
            Discover Souqh
          </Grid>
          <Grid
            className={clsx(WebStyles.paraBlackText, WebStyles.nunitoFont)}
            style={{
              fontSize: mdUp ? 28 : 14,
              lineHeight: mdUp ? "38px" : "19px",
              textAlign: "center",
              marginBottom: mdUp ? 12 : 8,
            }}
          >
            {/* Souqh delivers independent value proposition to Service Providers. */}
            The Ultimate Collaboration Platform for Realtors, Clients, and your Preferred Partners!
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
}
