import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import ServiceItem from "./ServiceItem";
import ServicesTitle from "./ServicesTitle";

const useStyles = makeStyles((theme) => ({}));

export default function Services({
  smUp,
  mdUp,
  lgUp,
  lgDown,
  xsUp,
  commonPadding,
}) {
  const classes = useStyles();

  const services = [
    {
      // name: "Lead Generation",
      name: "Home Ownership - ",
      subName: "Simplified",
      color: "#72cac9",
      width: "560px",
      // height: "330px",
      height: "516px",
      transform: smUp ? "none" : "scale(1,1.5)",
      // image: "/images/service_providers/lead_generation.svg",
      image: "/images/service_providers/customer360.svg",
      // description:
      //   "Find new clients by launching an online storefront within a few minutes. Customers can find your storefront, browse your services, review your ratings and connect with you within seconds.",
      description:
        "Invite your clients to a modernized homeownership experience. From financing to moving and home improvements, our homeownership hub includes all the self-serve tools your clients need to manage their entire homeownership journey – in one place",
    },
    {
      // name: "Digital Marketing",
      name: "Partner Referrals - ",
      subName: "Streamlined",
      color: "#72cac9",
      width: "670px",
      height: "516px",
      transform: smUp ? "none" : "scale(1,1.5)",
      // image: "/images/service_providers/digital_marketing.svg",
      image: "/images/service_providers/lead_generation.svg",
      // description:
      //   "Drive more traffic to your storefronts and grow you business, fast. Launch data-driven, marketing campaigns to help you feature your storefront, enabling higher ROI on your marketing dollars.",
      description: "Invite your preferred lenders, home inspectors, lawyers, movers, trades and general contractors to Souqh for free, and share your preferred partner network with your clients using our homeownership hub."
    },
    {
      // name: "360º Customer Management",
      name: "Collaboration - ",
      subName: "Redefined",
      color: "#F37C61",
      width: "402px",
      height: "516px",
      transform: smUp ? "none" : "scale(1,1.5)",
      // image: "/images/service_providers/customer360.svg",
      image: "/images/service_providers/Souqh Platform.svg",
      // description:
      //   "Deliver incredible customer experiences by managing your customer facing touch points - in one place.Invite customers, review complete customer profiles, manage documents and engage with associated service providers.",
      description: "Experience our streamlined, collaborative platform to provide a simplified homeowner experience to your clients and your partners. Simply connect, collaborate and transact with your clients and partners – all in one place."
    },
    {
      // name: "Document Management",
      name: "Marketing – ",
      subName: "On Auto-pilot",
      color: "#F37C61",
      width: "666px",
      height: "612px",
      transform: smUp ? "none" : "scale(1,1.5)",
      // image: "/images/service_providers/document_management.svg",
      image: "/images/service_providers/digital_marketing.svg",
      // description:
      //   "Make your document headaches a thing of the past. Our automated document workflows allow you to create, share, e-sign and manage all your documents in one streamlined portal.",
      description: "Expand your revenue opportunities and connect with potential clients using your profile on our marketplace. Launch data-driven, marketing campaigns to help you feature your profile, enabling higher ROI on your marketing dollars."
    },
    {
      // name: "eCommerce",
      name: "Technology – ",
      subName: "Centralized",
      color: "#B872AF",
      width: "650px",
      height: "464px",
      transform: smUp ? "none" : "scale(1,1.5)",
      // image: "/images/service_providers/ecommerce.svg",
      image: "/images/service_providers/document_management.svg",
      // description:
      //   "Create a hassle-free experience for your customers by allowing Souqh to take care of payment processing. Receive payments directly into your bank accounts using leading Stripe payments technology, right on our platform.",
      description: "eSign, calendar management, video chat, messaging, social media sync, scheduling, payment processing and much more – all consolidated – in one place.",
    },
    {
      // name: "Enterprise-Grade Security",
      name: "Security – ",
      subName: "Under control",
      color: "#B872AF",
      width: "290px",
      height: "512px",
      transform: smUp ? "scale(1, 1.2)" : "scale(1,1.5)",
      image: "/images/service_providers/enterprise.svg",
      description:
        "Rest easy knowing that you completely own and control your data. Our enterprise-grade cloud security, which runs on industry-leading Amazon cloud (Canada Region), protects your data using 256-bit SSL/TLS encryption.",
    },
  ];
  return (
    <Grid item xs={12} lg={12} container>
      <ServicesTitle
        smUp={smUp}
        mdUp={mdUp}
        lgUp={lgUp}
        lgDown={lgDown}
        commonPadding={commonPadding}
      />
      <Grid item xs={12} style={{ paddingBottom: mdUp ? 24 : 12 }}>
        {services.map((item, index) => {
          return (
            <Grid
              key={index}
              style={{ position: "relative", paddingBottom: 32 }}
            >
              <ServiceItem
                smUp={smUp}
                mdUp={mdUp}
                service={item}
                isEven={index % 2 === 0}
                lgUp={lgUp}
                lgDown={lgDown}
                commonPadding={commonPadding}
                index={index}
                xsUp={xsUp}
              />
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
}
