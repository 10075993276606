import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Avatar, Card, Container, Grid, Hidden, Typography } from "@material-ui/core";
import clsx from "clsx";
import WebStyles from "../../WebStyles.module.css";

export default function ReviewCarousel({ mdDown, mdUp, lgUp, commonPadding }) {
  const testimonialArray = [
    {
      gender: "female",
      image: "/images/home_page/tania.png",
      text: "At OREA, our mission centers around providing our Members with cutting-edge tools to serve their clients effectively. Souqh addresses a longstanding void by unifying the homeownership experience for Canadians. As a pioneering move, OREA is the first provincial association to offer the Souqh homeownership hub to all Members as a valuable affinity benefit.",
      author: [
        <p
          className={clsx(WebStyles.paraBlackText, WebStyles.hankrndFont)}
          style={{
            fontSize: mdUp ? 16 : 12,
            margin: 0,
          }}
        >
          <b>TANIA ARTENOSI</b>
        </p>,
        <p
          className={clsx(WebStyles.paraBlackText, WebStyles.hankrndFont)}
          style={{
            fontSize: mdUp ? 16 : 12,
            margin: 0,
          }}
        >
          PRESIDENT
        </p>,
        <p
          className={clsx(WebStyles.paraBlackText, WebStyles.hankrndFont)}
          style={{
            fontSize: mdUp ? 16 : 12,
            margin: 0,
          }}
        >
          OREA
        </p>,
      ],
    },
    {
      gender: "male",
      image: "/images/home_page/matthew_c.png",
      text: "Earlier this year, I was introduced to Souqh and my team and I couldn't be happier! It has simplified the home journey experience for both clients and agents. I also found the Souqh team went above and beyond with customer service. I highly recommend everyone join Souqh and experience the future of real estate and home service marketplace.",
      // author: "Zamir Alakozi, Lawyer, Advent Legal",
      author: [
        <p
          className={clsx(WebStyles.paraBlackText, WebStyles.hankrndFont)}
          style={{
            fontSize: mdUp ? 16 : 12,
            margin: 0,
          }}
        >
          <b>MATTHEW CAMPOLI</b>
        </p>,
        <p
          className={clsx(WebStyles.paraBlackText, WebStyles.hankrndFont)}
          style={{
            fontSize: mdUp ? 16 : 12,
            margin: 0,
          }}
        >
          TORONTO REALTOR & INVESTOR
        </p>,
        <p
          className={clsx(WebStyles.paraBlackText, WebStyles.hankrndFont)}
          style={{
            fontSize: mdUp ? 16 : 12,
            margin: 0,
          }}
        >
          EXP REALTY
        </p>,
      ],
    },
    {
      gender: "male",
      // image: "https://pbs.twimg.com/profile_images/1636295621094043649/bltKfurk_400x400.jpg",
      image: "/images/home_page/simeon.png",
      text: "Souqh is a tech platform that is geared to create a difference in client satisfaction. So this platform, specifically, creates an instant network of experts. It's a tremendous platform, a cohesive ecosystem and very natural to work within. So, I recommend it highly!",
      // author: "Zamir Alakozi, Lawyer, Advent Legal",
      author: [
        <p
          className={clsx(WebStyles.paraBlackText, WebStyles.hankrndFont)}
          style={{
            fontSize: mdUp ? 16 : 12,
            margin: 0,
          }}
        >
          <b>SIMEON PAPAILIAS</b>
        </p>,
        <p
          className={clsx(WebStyles.paraBlackText, WebStyles.hankrndFont)}
          style={{
            fontSize: mdUp ? 16 : 12,
            margin: 0,
          }}
        >
          MANAGING PARTNER
        </p>,
        <p
          className={clsx(WebStyles.paraBlackText, WebStyles.hankrndFont)}
          style={{
            fontSize: mdUp ? 16 : 12,
            margin: 0,
          }}
        >
          REC CANADA
        </p>,
      ],
    },
    {
      gender: "male",
      image: "/images/home_page/richard.jpg",
      text: "I love technology, anything that can make my life easier or make me more efficient. This way I can tell my clients or send them to Souqh. They can find people to service their needs, their everyday life and I'm happy especially if they're happy with the service.",
      // author: "Zamir Alakozi, Lawyer, Advent Legal",
      author: [
        <p
          className={clsx(WebStyles.paraBlackText, WebStyles.hankrndFont)}
          style={{
            fontSize: mdUp ? 16 : 12,
            margin: 0,
          }}
        >
          <b>RICHARD SILVER</b>
        </p>,
        <p
          className={clsx(WebStyles.paraBlackText, WebStyles.hankrndFont)}
          style={{
            fontSize: mdUp ? 16 : 12,
            margin: 0,
          }}
        >
          SOTHEBYS/REALTOR
        </p>,
        <p
          className={clsx(WebStyles.paraBlackText, WebStyles.hankrndFont)}
          style={{
            fontSize: mdUp ? 16 : 12,
            margin: 0,
          }}
        >
          SILVER BURTNICK & ASSOCIATES
        </p>,
      ],
    },
    {
      gender: "male",
      image: "https://media.licdn.com/dms/image/C5603AQF10_Ve2DP4Tg/profile-displayphoto-shrink_800_800/0/1538093576907?e=2147483647&v=beta&t=93YTV3cSWEyl8sgrc01X3wFE5PeetToqVVmgHn9J-S0",
      text: "Have used Souqh for over 1 year. Our law firm has had a great experience with high-quality referrals, outstanding customer service, and most of all, the opportunity to associate with an up-and-coming company that is making its mark in the real estate world.",
    // author: "Zamir Alakozi, Lawyer, Advent Legal",
      author: [
        <p
          className={clsx(WebStyles.paraBlackText, WebStyles.hankrndFont)}
          style={{
            fontSize: mdUp ? 16 : 12,
            margin: 0,
          }}
        >
          <b>ZAMIR ALAKOZI</b>
        </p>,
        <p
          className={clsx(WebStyles.paraBlackText, WebStyles.hankrndFont)}
          style={{
            fontSize: mdUp ? 16 : 12,
            margin: 0,
          }}
        >
          LAWYER AND CEO
        </p>,
        <p
          className={clsx(WebStyles.paraBlackText, WebStyles.hankrndFont)}
          style={{
            fontSize: mdUp ? 16 : 12,
            margin: 0,
          }}
        >
          ADVENT LEGAL
        </p>,
      ],
    },
    {
      gender: "male",
      image: "https://eqraz.com/documents/2023/03/Youseff-252x252.jpg",
      text: "I have been using Souqh since it started, and my experience as a business owner and my client's experiences as home buyers are very amazing. Souqh has made the home buying process very easy thanks to their marketplace and the different marketing tools for service providers and the security and ease of use for the home buyers.",
      // author: "Zamir Alakozi, Lawyer, Advent Legal",
      author: [
        <p
          className={clsx(WebStyles.paraBlackText, WebStyles.hankrndFont)}
          style={{
            fontSize: mdUp ? 16 : 12,
            margin: 0,
          }}
        >
          <b>YOUSSEF AZAD</b>
        </p>,
        <p
          className={clsx(WebStyles.paraBlackText, WebStyles.hankrndFont)}
          style={{
            fontSize: mdUp ? 16 : 12,
            margin: 0,
          }}
        >
          MORTGAGE BROKER
        </p>,
        <p
          className={clsx(WebStyles.paraBlackText, WebStyles.hankrndFont)}
          style={{
            fontSize: mdUp ? 16 : 12,
            margin: 0,
          }}
        >
          AZAD MORTGAGES
        </p>,
      ],
    },
    {
      gender: "female",
      // image: "https://cdn.realtor.ca/individual/TS636942988800000000/highres/1292491.jpg",
      image: "/images/home_page/stephanie.png",
      text: "I think Souqh is really about growing a community. It's making it so that consumer needs help in every aspect. Because when we become a homeowner you never know when all these challenges come out so I think it's helping build a community and building knowledge for that consumer to alleviate stress and mishaps on their part because it's all in one place.",
      // author: "Zamir Alakozi, Lawyer, Advent Legal",
      author: [
        <p
          className={clsx(WebStyles.paraBlackText, WebStyles.hankrndFont)}
          style={{
            fontSize: mdUp ? 16 : 12,
            margin: 0,
          }}
        >
          <b>STEPHANIE KING</b>
        </p>,
        <p
          className={clsx(WebStyles.paraBlackText, WebStyles.hankrndFont)}
          style={{
            fontSize: mdUp ? 16 : 12,
            margin: 0,
          }}
        >
          ASSOCIATE BROKER
        </p>,
        <p
          className={clsx(WebStyles.paraBlackText, WebStyles.hankrndFont)}
          style={{
            fontSize: mdUp ? 16 : 12,
            margin: 0,
          }}
        >
          RARE REAL ESTATE
        </p>,
      ],
    },

    {
      gender: "female",
      image: "",
      text: "I have used Souqh several times now to find reliable contractors such as painters, plumbers, pest control services, etc and have consistently received excellent services from the vendors on the platform at very competitive prices. Finally a solution that's helping home owners save time and making it simpler to manage their home services!",
      // author: "Chanda Logan, Residential Mortgage Agent, The Mortgage Division",
      author: [
        <p
          className={clsx(WebStyles.paraBlackText, WebStyles.hankrndFont)}
          style={{
            fontSize: mdUp ? 16 : 12,
            margin: 0,
          }}
        >
          <b>HUDA LAKHANI</b>
        </p>,
        <p
          className={clsx(WebStyles.paraBlackText, WebStyles.hankrndFont)}
          style={{
            fontSize: mdUp ? 16 : 12,
            margin: 0,
          }}
        >
          HOMEOWNER
        </p>,
        <p
          className={clsx(WebStyles.paraBlackText, WebStyles.hankrndFont)}
          style={{
            fontSize: mdUp ? 16 : 12,
            margin: 0,
          }}
        >
          GOOGLE REVIEW
        </p>,
      ],
    },
    {
      gender: "male",
      image: "",
      text: "Just signed up as a home owner. Pleasantly surprised to see how user friendly the website is and what an excellent platform they are providing. Looking forward to using this for my home ownership, buying, selling needs.",
      // author: "Zamir Alakozi, Lawyer, Advent Legal",
      author: [
        <p
          className={clsx(WebStyles.paraBlackText, WebStyles.hankrndFont)}
          style={{
            fontSize: mdUp ? 16 : 12,
            margin: 0,
          }}
        >
          <b>MOHAMMAD NADEEM</b>
        </p>,
        <p
          className={clsx(WebStyles.paraBlackText, WebStyles.hankrndFont)}
          style={{
            fontSize: mdUp ? 16 : 12,
            margin: 0,
          }}
        >
          HOMEOWNER
        </p>,
        <p
          className={clsx(WebStyles.paraBlackText, WebStyles.hankrndFont)}
          style={{
            fontSize: mdUp ? 16 : 12,
            margin: 0,
          }}
        >
          GOOGLE REVIEW
        </p>,
      ],
    },
  ];
  return (
    <Grid
      item
      container
      xs={12}
      style={{
        padding: commonPadding,
        backgroundColor: "#fdebe7",
        height: lgUp ? 696 : mdUp ? 435 : 370,
        marginBottom: lgUp ? 100 : mdUp ? 50 : 20,
        marginTop: lgUp ? 56 : 30,
      }}
    >
      <Container style={{ marginTop: lgUp ? 120 : mdUp ? 35 : 25 }}>
        <Container>
          <Carousel
            transitionTime={3000}
            autoPlay={true}
            showThumbs={false}
            showStatus={false}
          >
            {testimonialArray.map((data, index) => (
              <Card
                key={index}
                elevation={0}
                style={{
                  paddingRight: 10,
                  height: lgUp ? 440 : mdUp ? 374 : 320,
                  borderRadius: 10,
                }}
              >
                <Grid container item xs={12}
                  style={{ height: "inherit" }}
                >
                  <Hidden mdDown>
                    <Grid item xs={5} style={{ alignSelf: "center", justifyContent: "center", display: "flex" }}>
                      {/* <img
                        style={{ height: 400, width: 400 }}
                        src={
                          data.image ? data.image :
                          data.gender === "female"
                            ? "/images/home_page/female_testimonial.svg"
                            : "/images/home_page/male_testimonial.svg"
                        }
                        alt={
                          data.gender === "female"
                            ? "Female Testimonials"
                            : "Male Testimonials"
                        }
                        title={
                          data.gender === "female"
                            ? "Female Testimonials"
                            : "Male Testimonials"
                        }
                      ></img> */}
                      <Avatar alt={
                        data.gender === "female"
                          ? "Female Testimonials"
                          : "Male Testimonials"
                      } src={
                        data.image ? data.image :
                          "https://lh3.googleusercontent.com/0bBfFEkB6ypK6aYw3JAjrk9Kkut-mICiVuQBFBLPdJRcXmUs6RJv3TSEb0HrJteG9Eyx9ITFMEOW10FrqDC41hQVsaukAgdtsXdNjis=w512-l90-sg-rj-c0xffffff"
                      }
                        style={{ width: 200, height: 200 }}
                      />
                    </Grid>
                  </Hidden>
                  <Grid
                    xs={12}
                    md={12}
                    lg={7}
                    style={{
                      marginTop: mdUp ? 30 : 0,
                      paddingRight: 10,
                      padding: mdDown ? 20 : "0px 48px",
                    }}
                    item
                    // container
                    // align="left"
                    alignContent="center"
                    container
                  >
                    <Grid
                      item
                      xs={12}
                      className={clsx(
                        WebStyles.paraBlackText,
                        WebStyles.nunitoFont
                      )}
                      style={{
                        fontSize: mdUp ? 20 : 14,
                        // lineHeight: mdUp ? "39px" : "19.5px",
                        // fontStyle: "italic",
                        textAlign: "left",
                      }}
                    >
                      {data.text}
                    </Grid>
                    <Grid
                      className={clsx(
                        WebStyles.paraBlackText,
                        WebStyles.hankrndFont
                      )}
                      style={{
                        fontSize: mdUp ? 16 : 12,
                        // lineHeight: mdUp ? "30px" : "15px",
                        // marginTop: mdDown ? 20 : 0,
                        textAlign: "left",
                        marginTop: mdUp ? 24 : 12,
                      }}
                      item
                      xs={12}
                    >
                      {data.author}
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            ))}
          </Carousel>
        </Container>
      </Container>
    </Grid>
  );
}
