import { Grid, makeStyles, Typography, Container } from "@material-ui/core";
import React from "react";
import WebStyles from "../../WebStyles.module.css";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  oval: {
    height: 28,
    width: 28,
    backgroundColor: "#72CAC9",
  },
  border: {
    width: "302px",
    height: "338px",
    border: "2px solid #F37C61",
    borderRadius: "41px",
  },
  numbers: {
    fontSize: "60px",
    fontWeight: 800,
  },
}));

function ConpanyOverview({ smUp, mdUp, lgUp, xlUp, commonPadding }) {
  const classes = useStyles();

  const height = lgUp ? 420 : 360;
  const radius = height / 2;
  return (
    <Grid
      item
      container
      xs={12}
      style={{
        padding: commonPadding,
        position: "relative",
        zIndex: 1,
      }}
    >
      {smUp ? (
        <div
          style={{
            position: "absolute",
            backgroundColor: "#F37C61",
            height: height,
            left: -2000,
            right: lgUp ? "10%" : "-15%",
            bottom: 0,
            top: lgUp ? "-40%" : "-60%",
            zIndex: -1,
            borderTopRightRadius: lgUp ? radius : "0px",
            borderBottomRightRadius: lgUp ? radius : "0px",
            transform: "rotate(10deg)",
            transformOrigin: "center",
            opacity: 0.2,
          }}
        />
      ) : null}

      <Container>
        <Grid
          container
          item
          xs={12}
          spacing={lgUp ? 10 : mdUp ? 5 : 5}
          style={{ padding: smUp ? "0px 0px 0px 48px" : 40, marginBottom: 20 }}
        >
          <Grid
            container
            item
            xs={12}
            sm={4}
            md={4}
            lg={4}
            justifyContent={smUp ? "center" : "flex-end"}
            style={{ marginBottom: 20 }}
          >
            <Grid
              container
              item
              xs={12}
              md={12}
              lg={12}
              justifyContent="flex-end"
            >
              <img
                style={{ height: 130 }}
                src="/images/aboutus/Horse.svg"
                alt="Dedicated Souqsters - Souqh"
              />
            </Grid>
            <Grid
              container
              item
              xs={12}
              md={12}
              lg={12}
              style={{
                width: xlUp ? 302 : mdUp ? 171 : 132,
                height: xlUp ? 338 : mdUp ? 191 : 147,
                border: "2px solid #F37C61",
                borderRadius: xlUp ? 41 : mdUp ? 27 : 21,
              }}
              justifyContent="center"
              alignItems="center"
              direction="column"
            >
              <Grid
                className={clsx(
                  classes.numbers,
                  WebStyles.samonHeaderTxt,
                  WebStyles.hankrndFont
                )}
                style={{
                  fontSize: xlUp ? 80 : mdUp ? 50 : 40,
                  lineHeight: xlUp ? "74px" : mdUp ? "32px" : "28px",
                  marginBottom: xlUp ? 20 : 12,
                }}
              >
                14
              </Grid>
              <Grid
                className={clsx(WebStyles.barlowFont)}
                style={{
                  margin: "0 41px",
                  fontSize: xlUp ? 28 : mdUp ? 20 : 14,
                  lineHeight: xlUp ? "30px" : mdUp ? "28px" : "18px",
                }}
              >
                Dedicated Souqsters
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            sm={4}
            md={4}
            lg={4}
            justifyContent={smUp ? "center" : "flex-end"}
            style={{ marginBottom: 20 }}
          >
            <Grid
              container
              item
              xs={12}
              md={12}
              lg={12}
              justifyContent="flex-end"
              style={{ marginBottom: "-4px" }}
            >
              <img
                style={{ height: 130 }}
                src="/images/aboutus/brain.svg"
                alt="Combined Experience"
              />
            </Grid>
            <Grid
              container
              item
              xs={12}
              md={12}
              lg={12}
              style={{
                width: xlUp ? 302 : mdUp ? 171 : 132,
                height: xlUp ? 338 : mdUp ? 191 : 147,
                border: "2px solid #F37C61",
                borderRadius: xlUp ? 41 : mdUp ? 27 : 21,
              }}
              justifyContent="center"
              alignItems="center"
              direction="column"
            >
              <Grid container item justifyContent="center" alignItems="center">
                <Grid
                  className={clsx(
                    classes.numbers,
                    WebStyles.samonHeaderTxt,
                    WebStyles.hankrndFont
                  )}
                  style={{
                    fontSize: xlUp ? 80 : mdUp ? 50 : 40,
                    lineHeight: xlUp ? "74px" : mdUp ? "32px" : "28px",
                    marginBottom: xlUp ? 20 : 8,
                  }}
                >
                  300+
                </Grid>
              </Grid>
              <Grid container item justifyContent="center" alignItems="center">
                <Grid
                  style={{
                    margin: "0 41px",
                    fontSize: xlUp ? 28 : mdUp ? 20 : 14,
                    lineHeight: xlUp ? "30px" : mdUp ? "28px" : "18px",
                  }}
                  className={clsx(WebStyles.barlowFont)}
                >
                  years of combined experience
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            sm={4}
            md={4}
            lg={4}
            justifyContent={smUp ? "center" : "flex-end"}
            style={{ marginBottom: 20 }}
          >
            <Grid
              container
              item
              xs={12}
              md={12}
              lg={12}
              justifyContent="flex-end"
              style={{ marginBottom: "-4px" }}
            >
              <img
                style={{ height: 130 }}
                src="/images/aboutus/bulb.svg"
                alt="Advisory Board Members"
              />
            </Grid>
            <Grid
              container
              item
              xs={12}
              md={12}
              lg={12}
              style={{
                width: xlUp ? 302 : mdUp ? 171 : 132,
                height: xlUp ? 338 : mdUp ? 191 : 147,
                border: "2px solid #F37C61",
                borderRadius: xlUp ? 41 : mdUp ? 27 : 21,
              }}
              justifyContent="center"
              direction="column"
            >
              <Grid container item justifyContent="center" alignItems="center">
                <Grid
                  className={clsx(
                    classes.numbers,
                    WebStyles.samonHeaderTxt,
                    WebStyles.hankrndFont
                  )}
                  style={{
                    fontSize: xlUp ? 80 : mdUp ? 50 : 40,
                    lineHeight: xlUp ? "74px" : mdUp ? "32px" : "28px",
                    marginBottom: xlUp ? 20 : 8,
                  }}
                >
                  7
                </Grid>
              </Grid>
              <Grid container item justifyContent="center" alignItems="center">
                <Grid
                  style={{
                    margin: "0 41px",
                    fontSize: xlUp ? 28 : mdUp ? 20 : 14,
                    lineHeight: xlUp ? "30px" : mdUp ? "28px" : "18px",
                  }}
                  className={clsx(WebStyles.barlowFont)}
                >
                  Advisory Board Members
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
}

export default ConpanyOverview;
