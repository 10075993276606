import {
  Button,
  Container,
  Grid,
  Hidden,
  Tab,
  Tabs,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import React from "react";
import WebStyles from "../../WebStyles.module.css";
import clsx from "clsx";
import Fade from "@material-ui/core/Fade";
import HomeWebForm from "./HomeWebForm";
import { useHistory } from "react-router";
import { WEBSITE_DOMAIN_URL } from "../websiteConstants";

function HomeJournerySimplified({
  smUp,
  smDown,
  mdUp,
  lgUp,
  xlUp,
  mdDown,
  selectedTab,
  setselectedTab,
  commonPadding,
}) {
  const [value, setValue] = React.useState(0);

  const [open, setOpen] = React.useState(false);
  const [who, setWho] = React.useState("");
  let history = useHistory();

  const handleClickOpen = (who) => {
    setWho(who);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setselectedTab(newValue);
  };

  const AntTabs = withStyles({
    root: {
      borderBottom: "1px solid #e8e8e8",
    },
    indicator: {
      backgroundColor: "#F37C61",
    },
  })(Tabs);

  const AntTab = withStyles((theme) => ({
    root: {
      textTransform: "none",
      minWidth: 72,
      fontWeight: 700,
      marginRight: theme.spacing(4),
      fontFamily: ["Nunito Sans", "sans-serif"].join(","),
      color: "#72CAC9",
      "&:hover": {
        color: "#F37C61",
        opacity: 1,
      },
      "&$selected": {
        color: "#F37C61",
        fontWeight: 700,
      },
      "&:focus": {
        color: "#F37C61",
      },
    },
    selected: {},
  }))((props) => <Tab disableRipple {...props} />);

  return (
    <Grid
      item
      container
      xs={12}
      style={{
        backgroundImage: mdUp
          ? "url('/images/aboutus/green_patch.svg')"
          : "none",
        backgroundRepeat: "no-repeat",
        backgroundSize: "12%",
        padding: commonPadding,
        marginTop: xlUp ? 100 : mdUp ? 50 : 30,
      }}
    >
      <HomeWebForm
        open={open}
        who={who}
        handleClose={handleClose}
      ></HomeWebForm>
      <Container>
        <Container>
          <Grid container direction={mdUp ? "row" : "column-reverse"}>
            <Grid item md={5}>
              <AntTabs
                value={value}
                onChange={handleChange}
                aria-label="ant example"
              >
                <AntTab label="Home Buyers/Owners" />
                <AntTab label="Service Providers" />
              </AntTabs>
              <Grid container direction="column" justifyContent="flex-start">
                <Hidden smUp>
                  <Grid item md={7}>
                    {value === 0 && (
                      <Fade in={value === 0} timeout={500}>
                        {/* <img
                          style={{
                            width: xlUp ? 870 : mdUp ? 500 : 300,
                            height: xlUp ? 778 : mdUp ? 500 : 300,
                          }}
                          src="/images/home_page/Home-Journery-Simplified.svg"
                          alt="Home Journey - Souqh"
                          title="Home Journey - Souqh"
                        /> */}
                        <iframe
                          style={{
                            width: lgUp ? 550 : "100%",
                            height: lgUp ? 382 : mdUp ? 459 : 201,
                            marginTop: lgUp && 72
                          }}
                          src="https://www.youtube.com/embed/lNdzmecEj1A?autoplay=1&rel=0&mute=1&modestbranding=1&loop=0"
                          title="Souqh it up! Make homeownership stress-free!"
                          frameborder="0"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        ></iframe>
                      </Fade>
                    )}
                    {value === 1 && (
                      <Fade in={value === 1} timeout={500}>
                        {/* <img
                          style={{
                            width: xlUp ? 870 : mdUp ? 500 : 300,
                            height: xlUp ? 778 : mdUp ? 500 : 300,
                          }}
                          src="/images/service_providers/people.svg"
                          alt="People"
                          title="People"
                        /> */}
                        <iframe
                          style={{
                            width: lgUp ? 550 : "100%",
                            height: lgUp ? 382 : mdUp ? 459 : 201,
                            marginTop: mdUp && 72
                          }}
                          src="https://www.youtube.com/embed/2OjoPEBrkQI?autoplay=1&rel=0&mute=1&modestbranding=1&loop=0"
                          title="Souqh: The Ultimate Realtor Collaboration Platform for Seamless Homeowner Journeys!"
                          frameborder="0"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        ></iframe>
                      </Fade>
                    )}
                  </Grid>
                </Hidden>
                <Grid
                  item
                  className={clsx(
                    WebStyles.hankrndFont,
                    WebStyles.samonHeaderTxt
                  )}
                  align="left"
                  style={{
                    fontSize: xlUp ? 80 : mdUp ? 60 : 40,
                    marginTop: 15,
                    lineHeight: xlUp ? "74px" : mdUp ? "55px" : "37px",
                    fontWeight: 600,
                  }}
                >
                  {/* {value === 0
                    ? `Home journey, simplified`
                    : `Increase your opportunities`} */}
                  {value === 0
                    ? `Home Ownership, Simplified`
                    : `Strengthen homeowner relationships`}
                </Grid>
                <Grid
                  item
                  className={clsx(
                    WebStyles.barlowFont,
                    WebStyles.paraBlackText
                  )}
                  align="left"
                  style={{
                    fontSize: mdUp ? 28 : 14,
                    marginTop: 15,
                    lineHeight: mdUp ? "38px" : "19px",
                  }}
                >
                  {/* {value === 0
                    ? `The complete home buying and ownership journey is right at your fingertips, anywhere, anytime.`
                    : `Generate leads. Save time. Cut costs. Elevate your client experiences.`} */}
                  {value === 0
                    ? `Experience a modernized homeownership experience. Simply connect, collaborate and transact with real estate services and home improvement professionals – all in one place.`
                    : `Build long-term client relationships that increase repeat & referral business, while reducing marketing expenses, and expanding revenue opportunities.`}
                </Grid>
                <Grid
                  xs={12}
                  container
                  item
                  style={{ marginTop: mdUp ? 20 : 10 }}
                >
                  <Grid
                    container
                    item
                    // xs={12}
                    xs={8}
                    md={5}
                    lg={7}
                    alignContent="center"
                    // justifyContent={mdDown ? "flex-end" : "flex-start"}
                  >
                    <Button
                      style={{
                        backgroundColor: "#F37C61",
                        color: "#FFFFFF",
                        textTransform: "none",
                        borderRadius: 20,
                        // marginTop: smDown ? 20 : 0,
                        // marginLeft: lgUp ? 20 : 0,
                        padding: mdUp ? "8px 30px" : "8px 20px",
                        fontSize: mdUp ? 20 : 14,
                      }}
                      onClick={() => {
                        // handleClickOpen(
                        //   value === 0 ? "homeBuyer" : "serviceProvider"
                        // );
                        if (value === 0) {
                          window.open(
                            `https://${WEBSITE_DOMAIN_URL}/homebuyersignup`,
                            "_blank"
                          );
                        } else {
                          window.open(
                            `https://${WEBSITE_DOMAIN_URL}/new`,
                            "_blank"
                          );
                        }
                      }}
                    >
                      <Grid className={WebStyles.nunitoFont}>
                        {value === 0 ? "Sign up now for free!" : "Join Souqh Today"}
                      </Grid>
                    </Button>
                  </Grid>
                  <Grid
                    item
                    // xs={12}
                    xs={4}
                    md={6}
                    lg={4}
                    container
                    alignContent="center"
                  >
                    {/* <TextField fullWidth label="Your email"></TextField> */}
                    <Typography
                      className={WebStyles.nunitoFont}
                      style={{
                        color: "#F37C61",
                        cursor: "pointer",
                        fontSize: mdUp ? 20 : 14,
                      }}
                      onClick={() => {
                        // value === 0 ? "homeBuyer" : "serviceProvider"
                        history.push(
                          value === 0 ? "/home-buyers" : "/service-providers"
                        );
                      }}
                    >
                      Learn More
                      <img
                        src="/images/chevron-right-brand.svg"
                        style={{ height: 21, verticalAlign: "middle" }}
                        alt="Chevron Right Brand - Souqh"
                        title="Chevron Right Brand - Souqh"
                      ></img>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Hidden smDown>
              <Grid item md={7}>
                {value === 1 && (
                  <Fade in={value === 1} timeout={500}>
                    {/* <img
                      style={{
                        width: xlUp ? 870 : mdUp ? 500 : 300,
                        height: xlUp ? 778 : mdUp ? 500 : 300,
                      }}
                      src={"/images/service_providers/people.svg"}
                      alt="People"
                      title="People"
                    /> */}
                    <iframe
                      style={{
                        width: lgUp ? 550 : "100%",
                        height: lgUp ? 382 : mdUp ? 459 : 201,
                        marginTop: mdUp && 72
                      }}
                      src="https://www.youtube.com/embed/2OjoPEBrkQI?autoplay=1&rel=0&mute=1&modestbranding=1&loop=0"
                      title="Souqh: The Ultimate Realtor Collaboration Platform for Seamless Homeowner Journeys!"
                      frameborder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    ></iframe>
                  </Fade>
                )}
                {value === 0 && (
                  <Fade in={value === 0} timeout={500}>
                    {/* <img
                      style={{
                        width: xlUp ? 870 : mdUp ? 500 : 300,
                        height: xlUp ? 778 : mdUp ? 500 : 300,
                      }}
                      src={"/images/home_page/Home-Journery-Simplified.svg"}
                      alt="Home Journey - Souqh"
                      title="Home Journey - Souqh"
                    /> */}
                    <iframe
                      style={{
                        width: lgUp ? 550 : "100%",
                        height: lgUp ? 382 : mdUp ? 459 : 201,
                        marginTop: lgUp && 72
                      }}
                      src="https://www.youtube.com/embed/lNdzmecEj1A?autoplay=1&rel=0&mute=1&modestbranding=1&loop=0"
                      title="Souqh it up! Make homeownership stress-free!"
                      frameborder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    ></iframe>
                  </Fade>
                )}
              </Grid>
            </Hidden>
          </Grid>
        </Container>
      </Container>
    </Grid>
  );
}

export default HomeJournerySimplified;
